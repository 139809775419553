import { useState } from 'react';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import { Typography, ListItemText, ListItemAvatar, MenuItem, Button, Box, Link, Divider } from '@mui/material';
// utils
// import { fToNow } from '../../../utils/formatTime';
// _mock_
// import { _contacts } from '../../../_mock/arrays';
// components
import { CustomAvatar } from '../../../components/custom-avatar';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import MenuPopover from '../../../components/menu-popover';
import BadgeStatus from '../../../components/badge-status';
import { IconButtonAnimate } from '../../../components/animate';
import { useSessionContext } from 'contexts/session/useSessionContext';
import sessionUtil from 'utils/data/sessionUtil';
import { Link as RouterLink } from 'react-router-dom';
import { PATHS_SOLVER } from 'routes/paths';

// ----------------------------------------------------------------------
const initials = (name: string): string => {
  return name.split(' ').map((value) => {
    if (value.length >= 1) {
      return value[0].toUpperCase()
    }
    return '';
  }).join('');
}

const ITEM_HEIGHT = 64;

export default function ContactsPopover() {
  const session = useSessionContext();
  const theme = useTheme();

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const getAvatarColor = (username: string): string => {
    const value = username.length;
    switch (value % 5) {
      case 1:
        return theme.palette.primary.main;
      case 0:
        return theme.palette.warning.main;
      case 2:
        return theme.palette.error.main;
      case 3:
        return theme.palette.secondary.main;
      case 4:
        return theme.palette.success.main;
      case 5:
      default:
        return theme.palette.info.main;
    }
  }

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? 'primary' : 'default'}
        onClick={handleOpenPopover}
        sx={{
          width: 40,
          height: 40,
          ...(openPopover && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <Iconify icon="eva:people-fill" />
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 320 }}>
        <Typography variant="subtitle1" sx={{ p: 1.5 }}>
          My Cohort
        </Typography>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Scrollbar sx={{ height: session.cohortAccounts.length > 5 ? ITEM_HEIGHT * 4 : ITEM_HEIGHT * (session.cohortAccounts.length) }}>
          {session.cohortAccounts
            .map((a) => ({
              avatar: '',
              id: a.email,
              name: a.username,
              status: 'offline',
              lastActivity: new Date(0),
              role: a.accountRole,
            }))
            .map((contact) => (
              <MenuItem
                key={contact.id}
                sx={{
                  height: ITEM_HEIGHT,
                  cursor: 'unset'
                }}
              >
                <ListItemAvatar>
                  <CustomAvatar
                    src={contact.avatar}
                    BadgeProps={{
                      badgeContent: <BadgeStatus status={contact.status} />,
                    }}
                    sx={{ backgroundColor: getAvatarColor(contact.name), color: '#ffffff', }}
                  >{initials(contact.name)}</CustomAvatar>
                </ListItemAvatar>

                <ListItemText
                  primary={`${contact.name} (${sessionUtil.getUsersRole(contact.role)})`}
                  // secondary={contact.status === 'offline' ? fToNow(contact.lastActivity) : ''}
                  secondary={contact.id}
                  primaryTypographyProps={{ typography: 'subtitle2', sx: { mb: 0.25 } }}
                  secondaryTypographyProps={{ typography: 'caption' }}
                />
              </MenuItem>
            ))}
        </Scrollbar>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Box sx={{ pt: 1, }}>
          <Link to={PATHS_SOLVER.cohort} component={RouterLink} underline="none" color="inherit">
            <Button fullWidth disableRipple onClick={() => { handleClosePopover(); }}>
              View All
            </Button>
          </Link>
        </Box>
      </MenuPopover>
    </>
  );
}
