import React from "react";
import { CacheStateType, ActionsType, CacheTypes } from "../types";
import services from "@piccadilly-cloud/platform-services";

const mergeReducer = (state: CacheStateType, action: ActionsType) => {
  console.log(state, action);
  if (action.type === CacheTypes.USERS_CACHE_MERGE) {
    const users: any = {};
    state.usersCache.forEach((i) => {
      users[i.email] = i;
    });
    action.payload.users.forEach((i) => {
      users[i.email] = i;
    });
    return {
      ...state,
      usersCache: [...Object.values(users)],
    }
  }
  return { ...state, };
};

const replaceReducer = (state: CacheStateType, action: ActionsType) => {
  return {
    ...state,
  }
};

const removeReducer = (state: CacheStateType, action: ActionsType) => {
  console.log(state, action);
  if (action.type === CacheTypes.USERS_CACHE_REMOVE) {
    const users = state.usersCache.filter((user) => {
      return user.id !== action.payload.userId
    });

    return {
      ...state,
      usersCache: [...Object.values(users)],
    };
  }

  return { ...state };
};

const refreshUsersForCohortGroup = (token: string, vendor: string, cohortGroupId: string) => {
  return (dispatch: React.Dispatch<any>) => {
    services.edge.app.userAccount.getAllForCohortGroup(cohortGroupId)({ token, vendor, })
      .then((res) => {
        return dispatch({
          type: CacheTypes.USERS_CACHE_MERGE,
          payload: {
            users: res,
          },
        });
      }).catch((err) => {
        console.error('could not refresh users for cohort group', err);
      });
  }
}

const refreshUsers = (token: string, vendor: string) => {
  return (dispatch: React.Dispatch<any>) => {
    services.edge.app.userAccount.getAll()({ token, vendor: vendor})
      .then((res) => {
        return dispatch({
          type: CacheTypes.USERS_CACHE_MERGE,
          payload: {
            users: res,
          },
        });
      }).catch((err) => {
        console.error('could not refresh users for cohort group', err);
      });
  }
}

const removeUser = (userId: string) => {
  return (dispatch: React.Dispatch<any>) => {
    return dispatch({
      type: CacheTypes.USERS_CACHE_REMOVE,
      payload: {
        userId
      }
    })
  };
}

const usersCache = {
  reducers: {
    [CacheTypes.USERS_CACHE_MERGE]: mergeReducer,
    [CacheTypes.USERS_CACHE_REPLACE]: replaceReducer,
    [CacheTypes.USERS_CACHE_REMOVE]: removeReducer,
  },
  methods: {
    refreshUsersForCohortGroup,
    refreshUsers,
    removeUser
  },
};

export default usersCache;
