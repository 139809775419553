import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled, alpha, useTheme } from '@mui/material/styles';
import { bgGradient } from 'utils/cssStyles';
import { Box, Link, Container, Typography, Stack, } from '@mui/material';
import LoginForm from './LoginForm';
import LoginFormVendor from './LoginFormVendor';
import { PATH_AUTH } from 'routes/paths';
import { useSessionContext } from 'contexts/session/useSessionContext';
import { Helmet } from 'react-helmet-async';

const StyledRoot = styled('div')(({ theme }) => ({
  position: 'relative',
  ...bgGradient({
    color: alpha(theme.palette.background.default, theme.palette.mode === 'light' ? 0.9 : 0.94),
    imgUrl: '/assets/background/overlay_2.jpg',
  }),
  [theme.breakpoints.up('md')]: {
    height: '100%',
  },
}));


export default function Login() {
  const theme = useTheme();
  const session = useSessionContext();

  React.useEffect(() => {
    if (session.token === '') {
      window.location.reload();
    }
  }, [session.token]);

  return (
    <>
      <Helmet>
        <title>Login | {session.appConfig.appName}</title>
      </Helmet>
      <StyledRoot>
        <Container sx={{ height: 1, padding: { md: theme.spacing(10, 0), sm: theme.spacing(10, 0), xs: theme.spacing(5, 0) }, }}>
          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
            <Stack spacing={2} sx={{ width: { xs: 0.85, sm: 0.85, md: 0.5, }, mb: 5, position: 'relative' }}>
              <Typography variant="h4">Login</Typography>
              <Stack direction="row" spacing={0.5}>
                <Typography variant="body2">New user?</Typography>
                {!session.appConfig.landingSettings.useLandingOnly && (
                  <Link to={PATH_AUTH.register} component={RouterLink} variant="subtitle2">
                    Create an account
                  </Link>
                )}
                {session.appConfig.landingSettings.useLandingOnly && (
                  <Link to={PATH_AUTH.registerPasscode} component={RouterLink} variant="subtitle2">
                    Create an account
                  </Link>
                )}
              </Stack>
              {session.appConfig.landingSettings.useLandingOnly && (
                <LoginForm />
              )}
              {!session.appConfig.landingSettings.useLandingOnly && (
                <LoginFormVendor />
              )}
            </Stack>
          </Box>
        </Container>
      </StyledRoot>
    </>
  )
}