import LoadingScreenWithMessage from 'components/error-messages/PlatformFailure';
import React, { ReactNode } from 'react';
import { useSessionContext } from '../useSessionContext';
import services from '@piccadilly-cloud/platform-services';

export default function SessionGuard(props: {
  children: ReactNode;
}){   
  const session = useSessionContext();

  if (session.isInitialized && session.isFailedToFetch) {
    services.et.phoneHome.log(session, `session guard tripped\n${window.location.hostname}\n\n${window.navigator.userAgent}`)
    return (
      <>
        <LoadingScreenWithMessage message="" />;
      </>
    );
  }

  return (
    <>
      {props.children}
    </>
  );
}