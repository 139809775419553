import { useState, useCallback, useEffect, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// components
import LoadingScreen from 'components/loading-screen';
//
import Login from 'views/auth/Login';
import { useSessionContext } from '../useSessionContext';
import { usePLRecordContext } from 'contexts/plrecord/usePLRecordContext';
// import { fToNow } from 'utils/formatTime';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized, ...session } = useSessionContext();
  const plrecordContext = usePLRecordContext();

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
  const [token, setToken] = useState('');

  const RecordCB = useCallback(() => {
    if (session.token !== token) {
      setToken(session.token);
      plrecordContext.loadRecords(session.records);
    } else if (isInitialized && !plrecordContext.initialized) {
      plrecordContext.loadRecords(session.records);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, plrecordContext, session.records, session.token]);
  useEffect(RecordCB, [RecordCB, isInitialized])

  if (!isInitialized || !plrecordContext.initialized) {
    return <LoadingScreen />;
  }

  if (session.exp) {
    const currentTime = Date.now() / 1000;
    // if (process.env.REACT_APP_DEBUG_REDUX === 'true') {
    //   console.log('[session] token checked in auth guard; expires', fToNow(new Date(session.exp * 1000)));
    // }

    if (session.exp < currentTime) {
      // if (process.env.REACT_APP_DEBUG_REDUX === 'true') {
      //   console.log('[session] token expired; checked in auth guard');
      // }
      if (pathname !== requestedLocation) {
        setRequestedLocation(pathname);
      }
      return <Login />;
    }
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
