// scroll bar
import 'simplebar/src/simplebar.css';


// editor
import 'react-quill/dist/quill.snow.css';

import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/third_party/embedly.min.css";


// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/scroll-to-top';
import { SettingsProvider } from './components/settings';
import { AuthProvider } from './contexts/session/SessionContext';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { PLRecordProvider } from 'contexts/plrecord/PLRecordContext';
// import { NotificationProvider } from 'contexts/notification/NotificationContext';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import App from './App';
import { CacheProvider } from './contexts/cache/CacheContext';
import reportWebVitals from './reportWebVitals';

import ReactGa from 'react-ga4';


ReactGa.initialize("G-ZM733CTWZH");


const queryClient = new QueryClient()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <AuthProvider>
    <PLRecordProvider>
      <CacheProvider>
        {/* <NotificationProvider> */}
        <HelmetProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <QueryClientProvider client={queryClient}>
              <SettingsProvider>
                <BrowserRouter>
                  <ScrollToTop />
                  <App />
                </BrowserRouter>
              </SettingsProvider>
            </QueryClientProvider>
          </LocalizationProvider>
        </HelmetProvider>
        {/* </NotificationProvider> */}
      </CacheProvider>
    </PLRecordProvider>
  </AuthProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
