// routes

import { PATH_AUTH } from "routes/paths";

// ----------------------------------------------------------------------

export const jwtDecode = (token: string): any => {
  const unencode = atob(token);
  const base64Url = unencode.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp: number) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now() / 1000;

  // Token expires after 10 hours from issue
  const timeLeft = (exp - currentTime) * 1000;
  clearTimeout(expiredTimer);
  if (process.env.REACT_APP_DEBUG_REDUX === 'true') {
    console.log('[session] setting time to expire in', timeLeft)
  }


  expiredTimer = setTimeout(() => {
    if (process.env.REACT_APP_DEBUG_REDUX === 'true') {
      console.log('[session] removing access token')
    }
    localStorage.removeItem('accessToken');
    window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);

    // This function below will handle when token is expired
    const token = jwtDecode(accessToken);
    const { exp } = token; // ~3 days by minimals server
    tokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');

  }
};
