import services from "@piccadilly-cloud/platform-services";
import { ActionsType, CacheStateType, CacheTypes } from "../types";


const moduleOverviewCacheReducer = (state: CacheStateType, action: ActionsType) => {
  if (action.type === CacheTypes.SOLVER_OVERVIEW_CACHE_REFRESH) {
    state.solverCache = {
      overview: {...action.payload.overview},
      fetched: true,
    }
  }
  return { ...state, };
}

const cacheSolver = (token: string, accountId: string) => {
  return (dispatch: React.Dispatch<any>) => {
    services.edge.records.user.getForAccountOverview(accountId)({ token, })
      .then((res) => {
        dispatch({ type: CacheTypes.SOLVER_OVERVIEW_CACHE_REFRESH, payload: { overview: res, } })
      }).catch((err) => {
        console.error('could not cache module overviews', err);
      });
  }
}

const solverCache = {
  reducers: {
    [CacheTypes.SOLVER_OVERVIEW_CACHE_REFRESH]: moduleOverviewCacheReducer,
  },
  methods: {
    cacheSolver,
  }
}

export default solverCache;
