// import { noCase } from 'change-case';
import { useState, useEffect } from 'react';
// @mui
import {
  Box,
  // Stack,
  List,
  Badge,
  Button,
  // Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  // ListItemText,
  // ListItemAvatar,
  // ListItemButton,
} from '@mui/material';
// utils
// import { fToNow } from '../../../utils/formatTime';
// _mock_
// import { _notifications } from '../../../_mock/arrays';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import { useNavigate } from 'react-router-dom';
import { PATHS_NOTIFICATION } from 'routes/paths';
// import { useSessionContext } from 'contexts/session/useSessionContext';
// import { useNotificationContext } from 'contexts/notification/useNotificationContext';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const navigate = useNavigate();
  // const notificationCtx = useNotificationContext();
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  // const [notifications, setNotifications] = useState<CorrespondencePlatformNotice[]>([]);

  useEffect(() => {
    // notificationCtx.cacheNotifications(session.token, session.appConfig.vendor, 10);
    // platformServices.manifest.correspondence.getNotifications(session.token, session.appConfig.vendor, 5)
    //   .then((records) => {
    //     setNotifications(records);
    //   })
  }, []);

  // const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;
  const totalUnRead = 0;

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleMarkAllAsRead = () => {
    // setNotifications(
    //   notifications.map((notification) => ({
    //     ...notification,
    //     isUnRead: false,
    //   }))
    // );
  };

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? 'primary' : 'default'}
        onClick={handleOpenPopover}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 360, p: 0 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>

            {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography> */}
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
          // disablePadding
          // subheader={
          //   <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
          //     New
          //   </ListSubheader>
          // }
          >
            <Box
              display="flex"
              flexDirection="column"
              rowGap={1}
              sx={{
                px: 2,
              }}
            >
              {/* {notificationCtx.notices.slice(0, 5).map((r) => {
                return (
                  <Box key={`${r.id}`} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" columnGap={1}>
                    <Box display="flex" flexDirection="row" alignContent="center" justifyContent="center" alignItems="center" columnGap={1}>
                      <Tooltip title={r.sent ? 'Sent' : r.errMessage ? r.errMessage : 'Failed to send'}>
                        <Typography variant="body2" noWrap sx={{ color: r.sent ? '' : theme.palette.error.main }}>
                          {r.to[0].addressType === 'SMS' && (
                            <>
                              <Iconify icon="ph:chat-circle-dots" />
                            </>
                          )}
                          {r.to[0].addressType === 'EMAIL' && (
                            <>
                              <Iconify icon="ph:envelope" />
                            </>
                          )}
                        </Typography>
                      </Tooltip>

                      <Box display="flex" flexDirection="column">
                        <Typography variant="caption" noWrap sx={{ fontWeight: 700, }}>
                          {r.to[0].username}
                        </Typography>
                        <Typography variant='caption' sx={{ fontWeight: 400, fontSize: "0.65rem" }} noWrap>
                          {fToNow(r.createdAt)}
                        </Typography>
                      </Box>
                      <Typography variant='caption' noWrap>
                        {r.message.body}
                      </Typography>

                    </Box>
                  </Box>
                )
              })} */}
            </Box>
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple onClick={() => { handleClosePopover(); navigate(PATHS_NOTIFICATION.root) }}>
            Notification Center
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

// type NotificationItemProps = {
//   id: string;
//   title: string;
//   description: string;
//   avatar: string | null;
//   type: string;
//   createdAt: Date;
//   isUnRead: boolean;
// };

// function NotificationItem({ notification }: { notification: NotificationItemProps }) {
//   const { avatar, title } = renderContent(notification);

//   return (
//     <ListItemButton
//       sx={{
//         py: 1.5,
//         px: 2.5,
//         mt: '1px',
//         ...(notification.isUnRead && {
//           bgcolor: 'action.selected',
//         }),
//       }}
//     >
//       <ListItemAvatar>
//         <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
//       </ListItemAvatar>

//       <ListItemText
//         disableTypography
//         primary={title}
//         secondary={
//           <Stack direction="row" sx={{ mt: 0.5, typography: 'caption', color: 'text.disabled' }}>
//             <Iconify icon="eva:clock-fill" width={16} sx={{ mr: 0.5 }} />
//             <Typography variant="caption">{fToNow(notification.createdAt)}</Typography>
//           </Stack>
//         }
//       />
//     </ListItemButton>
//   );
// }

// ----------------------------------------------------------------------

// function renderContent(notification: NotificationItemProps) {
//   const title = (
//     <Typography variant="subtitle2">
//       {notification.title}
//       <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
//         &nbsp; {noCase(notification.description)}
//       </Typography>
//     </Typography>
//   );

//   if (notification.type === 'order_placed') {
//     return {
//       avatar: <img alt={notification.title} src="/assets/icons/notification/ic_package.svg" />,
//       title,
//     };
//   }
//   if (notification.type === 'order_shipped') {
//     return {
//       avatar: <img alt={notification.title} src="/assets/icons/notification/ic_shipping.svg" />,
//       title,
//     };
//   }
//   if (notification.type === 'mail') {
//     return {
//       avatar: <img alt={notification.title} src="/assets/icons/notification/ic_mail.svg" />,
//       title,
//     };
//   }
//   if (notification.type === 'chat_message') {
//     return {
//       avatar: <img alt={notification.title} src="/assets/icons/notification/ic_chat.svg" />,
//       title,
//     };
//   }
//   return {
//     avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
//     title,
//   };
// }
