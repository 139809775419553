import { PlatformPermissions, PublicAccount, AppConfig, SessionType, UserRecord, Session, MicroAccount, ContentSettings, UserSettings, BrandingSettings, LandingSettings, CohortGroups, ModuleType, AccountInvitation, Settings, Cohort, SecuritySettings } from "@piccadilly-cloud/platform-services";

export type ActionMapType<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type AuthStateType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  isFailedToFetch: boolean;

  platformPermissions: PlatformPermissions;
  account: PublicAccount;
  appConfig: AppConfig;
  cohortAccounts: PublicAccount[];
  sessionType: SessionType;
  records: UserRecord[],
  token: string;
  exp?: number;
};

export enum SessionTypes {
  INITIAL = 'session/INITIAL',
  LOGIN = 'session/LOGIN',
  REGISTER = 'session/REGISTER',
  LOGOUT = 'session/LOGOUT',
  UPDATE_ACCOUNT = 'session/UPDATE_ACCOUNT',
  UPDATE_APP_CONFIG = 'session/UPDATE_APP_CONFIG',
}

type SessionPayload = {
  [SessionTypes.INITIAL]: {
    isAuthenticated: boolean;
    isFailedToFetch?: boolean;
    session: Session;
  };
  [SessionTypes.LOGIN]: {
    session: Session;
  };
  [SessionTypes.REGISTER]: {
    session: Session;
  };
  [SessionTypes.LOGOUT]: undefined;
  [SessionTypes.UPDATE_ACCOUNT]: {
    account: PublicAccount,
  };
  [SessionTypes.UPDATE_APP_CONFIG]: {
    data: AppConfig,
    hostname: string,
  };
};

export type ActionsType = ActionMapType<SessionPayload>[keyof ActionMapType<SessionPayload>];

// ----------------------------------------------------------------------

export type SessionContextType = {
  method: 'jwt';
  isAuthenticated: boolean;
  isInitialized: boolean;
  isFailedToFetch: boolean;

  // for legacy use only!
  session: Session;

  platformPermissions: PlatformPermissions;
  account: PublicAccount;
  appConfig: AppConfig;
  cohortAccounts: MicroAccount[];
  sessionType: SessionType;
  records: UserRecord[],
  token: string;
  exp?: number;

  login: (token: string, email: string, password: string, appId?: string) => Promise<void>;
  loginFromAccessCode: (accessCode: string, token: string) => Promise<void>;
  register: (request: UserRegistrationRequest) => Promise<void>;
  registerWithDefaultApp: (request: AppRegistrationRequest) => Promise<void>;
  logout: () => Promise<void>;
  accountHooks: {
    updateCurrentAccount: (updatedAccount: PublicAccount) => void;
  };
  appConfigHooks: {
    updateSettings: (token: string, appId: string, settings: Settings) => Promise<AppConfig>,
    updateContentSettings: (token: string, appId: string, settings: ContentSettings) => Promise<AppConfig>,
    updateSecuritySettings: (token: string, appId: string, settings: SecuritySettings) => Promise<AppConfig>,
    updateUserSettings: (token: string, vendor: string, userSettings: UserSettings) => Promise<AppConfig>,
    updateBrandingSettings: (token: string, vendor: string, brandingSettings: BrandingSettings) => Promise<AppConfig>,
    updateLandingSettings: (token: string, vendor: string, landingSettings: LandingSettings) => Promise<AppConfig>,
    updateCohortGroup: (data: CohortGroups, appId: string, token: string) => Promise<AppConfig>,
    deleteCohortGroup: (referenceId: string, appId: string, token: string) => Promise<AppConfig>,
    createCohort: (data: Cohort, cohortGroupId: string,  vendor: string, token: string) => Promise<AppConfig>,
    updateCohort: (data: Cohort, cohortGroupId: string,  vendor: string, token: string) => Promise<AppConfig>,
    deleteCohort: (id: string, cohortGroupId: string,  vendor: string, token: string) => Promise<AppConfig>,
    updateModuleType: (item: ModuleType, appReferenceId: string, token: string) => Promise<AppConfig>,
    deleteModuleType: (dynamicContentTypeId: string, appReferenceId: string, token: string) => Promise<AppConfig>,
  };
  authHooks: {};
};

export type AppRegistrationRequest = {
  token: string;
  email: string;
  phoneNumber: string;
  password: string;
  name: string;
  appName: string;
  appHost: string;
  appId: string;
  accessCode?: string;
}

export type UserRegistrationRequest = {
  token: string;
  invitation: AccountInvitation;
  username: string;
  email: string;
  password: string;
  phoneNumber?: string;
  age?: string;
  gradeLevel?: string;
  race?: string;
  gender?: string;
}