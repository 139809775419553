import { Session } from "@piccadilly-cloud/platform-services";

const getAppName = (session: Session): string => {
  return session.appConfig.appName;
}

const session = {
  getAppName,
};

export default session;
