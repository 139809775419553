import { useState } from 'react';
import * as Yup from 'yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// auth
import { useSessionContext } from '../../../contexts/session/useSessionContext';
// components
import Iconify from '../../../components/iconify';
import FormProvider, { RHFTextField } from '../../../components/hook-form';
import { usePLRecordContext } from 'contexts/plrecord/usePLRecordContext';
import services from '@piccadilly-cloud/platform-services';

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  password: string;
  afterSubmit?: string;
};

export default function LoginForm() {
  const navigate = useNavigate();
  const session = useSessionContext();
  const { login } = useSessionContext();
  const plrecord = usePLRecordContext();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      if (plrecord.initialized) {
        plrecord.reset();
      }
      await login(session.token, data.email, data.password, session.appConfig.appId);
    } catch (error: any) {
      // console.error(error);
      const message = {
        severity: 'error',
        message: `login failed\n${data.email}\n${JSON.stringify(error)}`
      }
      services.edge.analytics.oplog.create(message)({ token: session.token, })
      // reset();

      setError('afterSubmit', {
        ...error,
        message: error.message,
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {/* {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>} */}
        {!!errors.afterSubmit &&
          <Alert severity="error">
            {errors.afterSubmit.message === 'INACTIVE' && (
              <>
                This account is inactive. Please contact an admin.
              </>
            )}
            {errors.afterSubmit.message !== 'INACTIVE' && (
              <>
                Invalid email address or password.
              </>
            )}
          </Alert>}

        <RHFTextField name="email" label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack alignItems="flex-end" sx={{ my: 2 }}>
        <Link
          to={PATH_AUTH.forgotPassword}
          component={RouterLink}
          variant="body2"
          color="inherit"
          underline="always"
        >
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        color="primary"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitSuccessful || isSubmitting}
      >
        Login
      </LoadingButton>
      {!!!session.appConfig.userSettings.disableTextMessageLogin && (
        <Button
          fullWidth
          color='primary'
          variant="outlined"
          size="large"
          onClick={() => { navigate(PATH_AUTH.textMessage); }}
          sx={{ my: 2, }}
        >
          Text Message Login
        </Button>
      )}
    </FormProvider>
  );
}
