import { ReactNode } from 'react';
import { Navigate, } from 'react-router-dom';

import { useSessionContext } from '../useSessionContext';
import { PATHS_SOLVER } from 'routes/paths';
import { AccountRole } from '@piccadilly-cloud/platform-services';

// ----------------------------------------------------------------------

type RootGuardProps = {
  children: ReactNode;
};

export default function RootGuard({ children }: RootGuardProps) {
  const { account } = useSessionContext();

  const facilitatorRoles: Record<AccountRole, boolean> = {
    'ROOT': true,
    'ACCOUNT_HOLDER': false,
    'FACILITATOR': false,
    'SINGLE_GROUP_FACILITATOR': false,
    'SINGLE_COHORT_FACILITATOR': false,
    'SOLVER': false,
    'GUEST': false,
  }

  if (!facilitatorRoles[account.accountRole]) {
    return <Navigate to={PATHS_SOLVER.dashboard} />
  }

  return <>{children}</>;
}
