import { SessionContextType } from "contexts/session/types";

const isPhoneNumberRequired = (session: SessionContextType): boolean => {
  return session.appConfig.appType === 'COMMERCIAL' || session.appConfig.notificationSettings.allowSMS;
}

const isCommercial = (session: SessionContextType): boolean => {
  return session.appConfig.appType === 'COMMERCIAL';
}

const isBillingEnabled = (session: SessionContextType): boolean => { return session.appConfig.productSettings.billing}
const isModulesEnabled = (session: SessionContextType): boolean => { return session.appConfig.productSettings.learnModules}
const isTrainingPlansEnabled = (session: SessionContextType): boolean => { return session.appConfig.productSettings.learnTrainingPlans}
const isAccessEnabled = (session: SessionContextType): boolean => { return session.appConfig.productSettings.access}
const isConnectEnabled = (session: SessionContextType): boolean => { return session.appConfig.productSettings.connect}
const isCertEnabled = (session: SessionContextType): boolean => { return session.appConfig.productSettings.cert}


const appConfig = {
  isPhoneNumberRequired,
  isCommercial,
  isBillingEnabled,
  isModulesEnabled,
  isTrainingPlansEnabled,
  isAccessEnabled,
  isConnectEnabled,
  isCertEnabled,
};

export default appConfig;
