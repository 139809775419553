import React from "react";
import { SessionTypes } from "../types";
import services, { AppConfig, BrandingSettings, Cohort, CohortGroups, ContentSettings, LandingSettings, ModuleType, PublicAccount, SecuritySettings, Settings, UserSettings } from "@piccadilly-cloud/platform-services";

const updateSettings = (token: string, appId: string, settings: Settings) => {
  return async (dispatch: React.Dispatch<any>) => {
    return services.edge.app.appConfig.updateSettings(settings)({ token: token, vendor: appId, })
      .then((res) => {
        const payload = { data: res, hostname: appId, }
        dispatch({ type: SessionTypes.UPDATE_APP_CONFIG, payload, });
        return Promise.resolve(res)
      }).catch((err) => {
        return Promise.reject(err);
      });
  };
}

const updateContentSettings = (token: string, appId: string, settings: ContentSettings) => {
  return async (dispatch: React.Dispatch<any>) => {
    return services.edge.app.appConfig.updateContentSettings(settings)({ token: token, vendor: appId, })
      .then((res) => {
        const payload = { data: res, hostname: appId, }
        dispatch({ type: SessionTypes.UPDATE_APP_CONFIG, payload, });
        return Promise.resolve(res)
      }).catch((err) => {
        return Promise.reject(err);
      });
  };
}

const updateSecuritySettings = (token: string, appId: string, settings: SecuritySettings) => {
  return async (dispatch: React.Dispatch<any>) => {
    return services.edge.app.appConfig.updateSecuritySettings(settings)({ token: token, vendor: appId })
    .then((res) => {
      const payload = { data: res, hostname: appId, }
      dispatch({ type: SessionTypes.UPDATE_APP_CONFIG, payload, });
      return Promise.resolve(res);
    }).catch((err) => {
      return Promise.reject(err);
    })
  }
}

const updateCohortGroup = (data: CohortGroups, appId: string, token: string) => {
  return async (dispatch: React.Dispatch<any>) => {
    return services.edge.app.appConfig.updateCohortGroupSettings_UpdateCohortGroup(data.referenceId, data)({ token: token, vendor: appId, })
      .then((res) => {
        const payload = { data: res, hostname: appId, }
        dispatch({ type: SessionTypes.UPDATE_APP_CONFIG, payload, });
        return Promise.resolve(res)
      }).catch((err) => {
        return Promise.reject(err);
      });
  };
}

const deleteCohortGroup = (referenceId: string, appId: string, token: string) => {
  return async (dispatch: React.Dispatch<any>) => {
    return services.edge.app.appConfig.updateCohortGroupSettings_DeleteCohortGroup(referenceId)({ token, vendor: appId, })
      .then((res) => {
        const payload = { data: res, hostname: appId, }
        dispatch({ type: SessionTypes.UPDATE_APP_CONFIG, payload, });
        return Promise.resolve(res)
      }).catch((err) => {
        return Promise.reject(err);
      });
  };
}

const createCohort = (data: Cohort, cohortGroupId: string, vendor: string, token: string) => {
  return async (dispatch: React.Dispatch<any>) => {
    return services.edge.app.appConfig.updateCohortGroupSettings_CreateCohortForGroup(cohortGroupId, data)({ token, vendor, })
      .then((res) => {
        const payload = { data: res, hostname: vendor, }
        dispatch({ type: SessionTypes.UPDATE_APP_CONFIG, payload, });
        return Promise.resolve(res)
      }).catch((err) => {
        return Promise.reject(err);
      });
  };
}

const updateCohort = (data: Cohort, cohortGroupId: string, vendor: string, token: string) => {
  return async (dispatch: React.Dispatch<any>) => {
    return services.edge.app.appConfig.updateCohortGroupSettings_UpdateCohortForGroup(cohortGroupId, data.referenceId, data)({ token, vendor, })
      .then((res) => {
        const payload = { data: res, hostname: vendor, }
        dispatch({ type: SessionTypes.UPDATE_APP_CONFIG, payload, });
        return Promise.resolve(res)
      }).catch((err) => {
        return Promise.reject(err);
      });
  };
}

const deleteCohort = (cohortId: string, cohortGroupId: string, vendor: string, token: string) => {
  return async (dispatch: React.Dispatch<any>) => {
    return services.edge.app.appConfig.updateCohortGroupSettings_DeleteCohortForGroup(cohortGroupId, cohortId) ({ token, vendor, })
      .then((res) => {
        const payload = { data: res, hostname: vendor, }
        dispatch({ type: SessionTypes.UPDATE_APP_CONFIG, payload, });
        return Promise.resolve(res)
      }).catch((err) => {
        return Promise.reject(err);
      });
  };
}

export function updateModuleType(item: ModuleType, appReferenceId: string, token: string) {
  return (dispatch: React.Dispatch<any>): Promise<AppConfig> => {
    return services.edge.app.appConfig.updateContentSettings_UpdateModuleType(item.id, item)({ token: token, vendor: appReferenceId, })
      .then((res) => {
        const payload = { data: res, hostname: appReferenceId, }
        dispatch({ type: SessionTypes.UPDATE_APP_CONFIG, payload, });
        return Promise.resolve(res)
      }).catch((err) => {
        return Promise.reject(err);
      });
  }
}

export function deleteModuleType(dynamicContentTypeId: string, appReferenceId: string, token: string) {
  return (dispatch: React.Dispatch<any>): Promise<AppConfig> => {
    return services.edge.app.appConfig.updateContentSettings_DeleteModuleType(dynamicContentTypeId)({ token: token, vendor: appReferenceId, })
      .then((res) => {
        const payload = { data: res, hostname: appReferenceId, }
        dispatch({ type: SessionTypes.UPDATE_APP_CONFIG, payload, });
        return Promise.resolve(res)
      }).catch((err) => {
        return Promise.reject(err);
      });
  }
}

const updateBrandingSettings = (token: string, vendor: string, settings: BrandingSettings) => {
  return async (dispatch: React.Dispatch<any>) => {
    return services.edge.app.appConfig.updateBrandingSettings(settings)({ token, vendor, })
      .then((res) => {
        const payload = { data: res, hostname: vendor, }
        dispatch({ type: SessionTypes.UPDATE_APP_CONFIG, payload, });
        return Promise.resolve(res)
      }).catch((err) => {
        return Promise.reject(err);
      });
  };
}

const updateLandingSettings = (token: string, vendor: string, settings: LandingSettings) => {
  return async (dispatch: React.Dispatch<any>) => {
    return services.edge.app.appConfig.updateLandingSettings(settings)({ token, vendor, })
      .then((res) => {
        const payload = { data: res, hostname: vendor, }
        dispatch({ type: SessionTypes.UPDATE_APP_CONFIG, payload, });
        return Promise.resolve(res)
      }).catch((err) => {
        return Promise.reject(err);
      });
  };
}

const updateUserSettings = (token: string, vendor: string, settings: UserSettings) => {
  return async (dispatch: React.Dispatch<any>) => {
    return services.edge.app.appConfig.updateUserSettings(settings)({ token, vendor, })
      .then((res) => {
        const payload = { data: res, hostname: vendor, }
        dispatch({ type: SessionTypes.UPDATE_APP_CONFIG, payload, });
        return Promise.resolve(res)
      }).catch((err) => {
        return Promise.reject(err);
      });
  };
}

const updateCurrentAccount = (updatedAccount: PublicAccount) => {
  return async (dispatch: React.Dispatch<any>) => {
    const payload = { account: updatedAccount, }
    dispatch({ type: SessionTypes.UPDATE_ACCOUNT, payload, });
  };
}


const appConfigActions = {
  updateSettings,
  updateContentSettings,
  updateSecuritySettings,
  updateCohortGroup,
  deleteCohortGroup,
  createCohort,
  updateCohort,
  deleteCohort,
  updateModuleType,
  deleteModuleType,
  updateCurrentAccount,
  updateBrandingSettings,
  updateLandingSettings,
  updateUserSettings,
  // updateAppConfig,
};

export default appConfigActions;
