import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
// hooks
import useActiveLink from 'hooks/useActiveLink';
//
import { NavListProps } from 'components/nav-section/types';
import NavItem from './ControlItem';

// ----------------------------------------------------------------------

type NavListRootProps = {
  data: NavListProps & { onClick?: VoidFunction, };
  depth: number;
  hasChild: boolean;
};

export default function NavList({ data, depth, hasChild }: NavListRootProps) {
  const navRef = useRef(null);

  const { pathname } = useLocation();

  const { active, isExternalLink } = useActiveLink(data.path);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = '';
    } else {
      document.body.style.overflow = '';
    }
  }, [open]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <NavItem
        ref={navRef}
        item={data}
        depth={depth}
        open={open}
        active={active}
        isExternalLink={isExternalLink}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
      />
    </>
  );
}

// ----------------------------------------------------------------------