// routes
import { PATHS_ADMIN, PATHS_APP, PATHS_BUILD, PATHS_MANAGE, PATHS_PLAN, } from 'routes/paths';

import Iconify from 'components/iconify';
import sessionUtil from 'utils/data/sessionUtil';
import { SessionContextType } from 'contexts/session/types';
import ctrl from '@piccadilly-libs-backend/ctrl';


// ----------------------------------------------------------------------
const getConfig = (session: SessionContextType) => {
  const permissions = ctrl.permissions.getForUser(session);

  const navConfig = [
    {
      subheader: 'App',
      items: [
        ...(permissions.as_admin.nav.dashboard_visible ? [{
          title: 'dashboard',
          icon: <Iconify icon="ph:chalkboard" />,
          path: PATHS_MANAGE.dashboard,
        }] : []),
        ...(permissions.as_admin.nav.users_visible ? [{
          title: 'users',
          path: PATHS_MANAGE.root,
          icon: <Iconify icon="ph:users-three" />,
          children: [
            ...(permissions.as_admin.nav.users_invitations_visible ? [{
              title: 'invitations',
              path: '/manage/users/invitations',
              // icon: <Iconify icon="material-symbols:send-outline-rounded" />,
            }] : []),
            ...(permissions.as_admin.nav.users_all_users_visible ? [{
              title: 'all users',
              path: '/manage/users/default',
              // icon: <Iconify icon="ph:users" />,
            }] : []),
            ...(permissions.as_admin.nav.users_organize_visible ? [{
              title: 'organize',
              path: '/manage/users/organization',
              // icon: <Iconify icon="fluent:organization-horizontal-20-regular" />,
            }] : []),
          ]
        }] : []),
      ],
    },
    {
      subheader: 'Content',
      items: [
        ...(permissions.as_admin.nav.training_plan_visible ? [{
          title: session ? sessionUtil.getTrainingPlanLabel(true)(session) : 'training plans',
          // icon: <Iconify icon="mdi:teach-poll" />,
          icon: <Iconify icon="ph:train-simple" />,
          path: PATHS_PLAN.root,
          children: [
            ...(permissions.as_admin.nav.training_plan_manage_visible ? [{
              title: 'Manage',
              path: PATHS_PLAN.manage,
              // icon: <Iconify icon="fluent-mdl2:build-queue-new" />,
            }] : []),
          ]
        }] : []),
        ...(permissions.as_admin.nav.module_visible ? [{
          title: session ? sessionUtil.getModuleLabel(true)(session) : 'modules',
          path: PATHS_BUILD.root,
          icon: <Iconify icon="ph:books" />,
          children: [
            ...(permissions.as_admin.nav.module_manage_visible ? [{
              title: 'Manage',
              path: PATHS_BUILD.list,
              // icon: <Iconify icon="fluent-mdl2:build-queue-new" />,
            }] : []),
            ...(permissions.as_admin.nav.module_organize_visible ? [{
              title: 'Organize',
              path: PATHS_BUILD.organization,
              // icon: <Iconify icon="fluent:organization-horizontal-20-regular" />,
            },] : []),
          ]
        }] : []),
      ],
    },
    {
      subheader: 'Settings',
      items: [
        ...(permissions.as_admin.nav.app_visible ? [{
          title: 'app',
          icon: <Iconify icon="ph:app-window" />,
          path: PATHS_APP.root,
          children: [
            ...(permissions.as_admin.nav.app_settings_visible ? [{
              title: 'settings',
              path: PATHS_APP.app,
              // icon: <Iconify icon="ph:gear" />,
            }] : []),
            ...(permissions.as_admin.nav.app_profile_visible ? [{
              title: 'profile',
              path: PATHS_APP.profile,
              // icon: <Iconify icon="ph:user" />,
            }] : []),
            ...(session.appConfig.appType === 'COMMERCIAL' ? [{
              title: 'help',
              path: PATHS_APP.help,
              // icon: <Iconify icon="ph:user" />,
            }] : []),
          ]
        }] : []),
        ...(permissions.as_admin.nav.piccadilly_visible ? [{
          title: 'Platform',
          icon: <Iconify icon="ph:air-traffic-control" />,
          path: PATHS_ADMIN.dashboard,
          children: [
            {
              title: 'app management',
              path: PATHS_ADMIN.appManagement,
              // icon: <Iconify icon="ph:lamp" />
            }
          ],
        }] : []),
      ]
    }
  ];
  return navConfig;
}

export default getConfig;
