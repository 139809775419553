import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  height?: number;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, height, sx, ...other }, ref) => {
    const logo = (
      <Box
        component="img"
        src="/volker/logo/tulip-main.png"
        sx={{ height: height ? height : 40, cursor: 'pointer', ...sx }}
      />
    );

    if (disabledLink) {
      return <>{logo}</>;
    }

    return (
      <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
