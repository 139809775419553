import Iconify from '../../../components/iconify';
// ----------------------------------------------------------------------

const getNavConfig = (useLandingOnly: boolean) => {
  return [
    {
      title: 'Home',
      icon: <Iconify icon="eva:home-fill" />,
      path: '/',
    },
    ...(!useLandingOnly ? [
      {
        title: 'About Us',
        icon: <Iconify icon="eva:home-fill" />,
        path: '/about-us',
      },
      {
        title: 'Get Started',
        icon: <Iconify icon="ic:round-app-registration" />,
        path: '/register'
      }
    ] : []),
    {
      title: 'Access Code',
      icon: <Iconify icon="ic:round-app-registration" />,
      path: '/register/access-code'
    },
  ];
}

export default getNavConfig;
