import { ActionsType, AuthStateType, SessionTypes } from "../types";

const updateReducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === SessionTypes.UPDATE_ACCOUNT) {
    return {  
      ...state,
      account: action.payload.account,
    };
  }
  return { ...state, };
}

const account = {
  reducers: {
    [SessionTypes.UPDATE_ACCOUNT]: updateReducer,
  }
};

export default account;
