import { PublicAccount, AppConfig, PlatformPermissions, Session } from "@piccadilly-cloud/platform-services";

const account: PublicAccount = {
  username: "",
  email: "",
  phoneNumber: "",
  cohortId: "",
  cohortGroupId: "",
  accountType: "COMMERCIAL",
  accountRole: "GUEST",
  createdAt: new Date(),
  updatedAt: new Date(),
  lastSessionAt: new Date(),
  active: false,
  enabled: false,
  vendor: "",
  notificationSettings: {
    email: false,
    emailVerified: false,
    phoneNumber: false,
    phoneNumberVerified: false,
  },
  id: "",
  demographicData: {
    age: '',
    gender: '',
    race: '',
    gradeLevel: '',
  }
};

const appConfig: AppConfig = {
  id: "",
  appType: "COMMERCIAL",
  vendor: "",
  createdAt: new Date(),
  updatedAt: new Date(),
  enabled: false,
  tags: [],
  parentVendor: '',
  appHost: "",
  appName: "",
  appId: "",
  deleted: false,
  brandingSettings: {
    primaryLogoUrl: "",
    secondaryLogoUrl: "",
  },
  contentSettings: {
    moduleLabelSingle: 'Module',
    moduleLabelPlural: 'Modules',
    trainingPlanLabelSingle: 'Training Plan',
    trainingPlanLabelPlural: 'Training Plans',
    moduleTypes: [],
  },
  cohortGroupSettings: {
    groupLabel: "",
    cohortGroups: [],
  },
  userSettings: {
    facilitatorLabel: "",
    solverLabel: "",
    disableTextMessageLogin: true,
    disablePasswordLogin: true,
  },
  landingSettings: {
    useLandingOnly: false,
    landingLogoUrl: '',
    landingHeroUrl: '',
  },
  productSettings: {
    billing: false,
    learnModules: false,
    learnTrainingPlans: false,
    access: false,
    connect: false,
    cert: false,
  },
  securitySettings: {
    usePolicyAgreement: false,
    policyAgreement: {
      title: "",
      body: ""
    },
  },
  notificationSettings: {
    allowEmail: false,
    requireEmailPresent: false,
    requireEmailVerification: false,
    allowSMS: false,
    requireSMSPresent: false,
    requireSMSVerification: false,
    allowInterPlatform: false,
  },
}

const platformPermissions: PlatformPermissions = {
  as_admin: {
        nav: {
          piccadilly_visible: false,
          dashboard_visible: false,
          training_plan_visible: false,
          training_plan_manage_visible: false,
          training_plan_track_visible: false,
          training_plan_fileManager_visible: false,
          module_visible: false,
          module_manage_visible: false,
          module_organize_visible: false,
          users_visible: false,
          users_invitations_visible: false,
          users_all_users_visible: false,
          users_organize_visible: false,
          organization_visible: false,
          app_visible: false,
          app_settings_visible: false,
          app_profile_visible: false,
          notifications_visible: false,
          my_cohort_visible: false,
          my_profile_visible: false,
        },
        restrict_cohort_group: false,
        restrict_cohort: false,
        dashboard_visible: false,
        dashboard_can_see_activity: false,
        dashboard_can_see_modules: false,
        dashboard_can_see_users: false,
        modules_can_view: false,
        modules_can_edit: false,
        modules_can_delete: false,
        modules_can_edit_template: false,
        modules_can_delete_template: false,
        training_plans_can_view: false,
        training_plans_can_edit: false,
        training_plans_can_delete: false,
        file_manager_can_view: false,
        file_manager_can_review: false,
        file_manager_can_download: false,
        invitations_can_send: false,
        invitations_can_delete: false,
        users_can_view: false,
        users_can_edit: false,
        users_can_delete: false,
        platform_config_visible: false,
  },
  as_user: {
    can_edit_own_role: false,
    can_edit_own_cohort_group: false,
    can_edit_own_cohort: false,
  },
  as_solver: {},
}

const session: Session = {
  account,
  appConfig,
  cohortAccounts: [],
  sessionType: 'UNAUTHENTICATED',
  token: '',
  records: [],
  platformPermissions: platformPermissions,
}

const defaults = {
  account,
  appConfig,
  platformPermissions,
  session,
};

export default defaults;