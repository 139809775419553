import React from 'react';
import { PLRecordTypes } from './types';
import services, { UserRecord, UserRecordAnnotation, UserRecordRequest } from '@piccadilly-cloud/platform-services';

const reset = () => {
  return (dispatch: React.Dispatch<any>) => {
    dispatch({ type: PLRecordTypes.RESET, });
  }
}

const loadRecords = (records: UserRecord[], merge?: boolean) => {
  return (dispatch: React.Dispatch<any>) => {
    dispatch({ type: PLRecordTypes.LOAD_RECORDS, payload: { records, merge, }})
  }
}

const createRecord = (token: string, recordRequest: UserRecordRequest) => {
  return (dispatch: React.Dispatch<any>) => {
    return services.edge.records.user.create(recordRequest)({ token, vendor: recordRequest.vendor, })
      .then((record) => {
        dispatch({ type: PLRecordTypes.CREATE_RECORD, payload: { record, }})
        return record
      }).catch((err) => {
        console.error('error creating record', err);
        return Promise.reject(err);
      })
  }
}

const createPreviewRecord = (recordRequest: UserRecordRequest) => {
  return (dispatch: React.Dispatch<any>) => {
    const record: UserRecord = {
      id: `preview-${recordRequest.id}`,
      vendor: recordRequest.vendor,
      createdAt: new Date(Date.now()),
      updatedAt: new Date(Date.now()),
      deleted: false,
      keys: recordRequest.keys,
      data: recordRequest.data,
      annotation: recordRequest.annotation!,
      timestamps: [new Date(Date.now())],
      oldAnnotations: [],
    };
    dispatch({ type: PLRecordTypes.CREATE_RECORD, payload: { record, } })
    return record
  }
} 

const invalidateRecord = (token: string, appId: string, recordId: string) => {
  return (dispatch: React.Dispatch<any>) => {
    return services.edge.records.user.invalidate(recordId)({ token, vendor: appId, })
      .then((record) => {
        dispatch({ type: PLRecordTypes.INVALIDATE_RECORD, payload: { recordId, }})
        return Promise.resolve();
      }).catch((err) => {
        console.error('error invalidating record', err);
        return Promise.reject(err);
      })
  }
}

const invalidateRecordByAdmin = (token: string, appId: string, recordId: string) => {
  return (dispatch: React.Dispatch<any>) => {
    services.edge.records.user.invalidateByAdmin(recordId)({ token, vendor: appId, })
      .then((record) => {
        dispatch({ type: PLRecordTypes.INVALIDATE_RECORD, payload: { recordId, }})
      }).catch((err) => {
        console.error('error invalidating record', err);
      })
  }
}

const invalidateAllRecordsByAdmin = (token: string, appId: string, records: string[]) => {
  return (dispatch: React.Dispatch<any>) => {
    services.edge.records.user.invalidateAllByAdmin({ ids: records })({ token, vendor: appId, })
      .then((record) => {
        dispatch({ type: PLRecordTypes.INVALIDATE_RECORDS, payload: { recordIds: records, } })
      }).catch((err) => {
        console.error('error invalidating record', err);
      })
  }
}

const updateAnnotation = (token: string, appId: string, recordId: string, annotation: UserRecordAnnotation) => {
  return (dispatch: React.Dispatch<any>) => {
    services.edge.records.user.updateAnnotation(recordId, annotation)({ token, vendor: appId, })
      .then((record) => {
        dispatch({ type: PLRecordTypes.CREATE_RECORD, payload: { record, }})
      }).catch((err) => {
        console.error('error creating record', err);
      })
  }
}

const actions = {
  reset,
  loadRecords,
  createRecord,
  createPreviewRecord,
  invalidateRecord,
  invalidateRecordByAdmin,
  invalidateAllRecordsByAdmin,
  updateAnnotation,
};

export default actions;
