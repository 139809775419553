import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Tooltip, Link, ListItemText, Box, useTheme } from '@mui/material';
// auth
import RoleBasedGuard from 'contexts/session/guards/RoleBasedGuard';
//
import { NavItemProps } from 'components/nav-section/types';
import { StyledItem, StyledIcon } from 'components/nav-section/mini/styles';
import Iconify from 'components/iconify';

// ----------------------------------------------------------------------

const NavItem = forwardRef<HTMLDivElement, NavItemProps>(
  ({ item, depth, open, active, isExternalLink, ...other }, ref) => {

    const { title, path, icon, children, disabled, caption, roles, onClick } = item;

    const theme = useTheme();

    const subItem = depth !== 1;

    const renderContent = (
      <StyledItem
        ref={ref}
        open={open}
        depth={depth}
        active={true}
        disabled={disabled}
        {...other}
      >
        {icon && <StyledIcon sx={{ color: active ? theme.palette.primary.main : theme.palette.text.secondary}}>{icon}</StyledIcon>}

        <ListItemText
          primary={title}
          primaryTypographyProps={{
            noWrap: true,
            sx: {
              width: 100,
              fontSize: 10,
              lineHeight: '16px',
              marginBottom: '6px',
              textAlign: 'center',
              color: theme.palette.text.secondary,
              ...(active && {
                fontWeight: 'fontWeightMedium',
                color: theme.palette.primary.main,
              }),
              ...(subItem && {
                fontSize: 14,
                width: 'auto',
                textAlign: 'left',
              }),
            },
          }}
        />

        {caption && (
          <Tooltip title={caption} arrow placement="right">
            <Iconify
              icon="eva:info-outline"
              width={16}
              sx={{
                top: 11,
                left: 6,
                position: 'absolute',
                background: theme.palette.text.primary,
                color: theme.palette.text.primary,
              }}
            />
          </Tooltip>
        )}

        {!!children && (
          <Iconify
            width={16}
            icon="eva:chevron-right-fill"
            sx={{
              top: 11,
              right: 6,
              position: 'absolute',
              color: theme.palette.text.primary,
              background: theme.palette.text.primary,
            }}
          />
        )}
      </StyledItem>
    );

    const renderItem = () => {

      // Action
      if (onClick) {
        return (
          <Box
            onClick={onClick}
          >
            {renderContent}
          </Box>
        )
      }

      // ExternalLink
      if (isExternalLink)
        return (
          <Link href={path} target="_blank" rel="noopener" underline="none">
            {renderContent}
          </Link>
        );

      // Default
      return (
        <Link component={RouterLink} to={path} underline="none">
          {renderContent}
        </Link>
      );
    };

    return <RoleBasedGuard roles={roles}> {renderItem()} </RoleBasedGuard>;
  }
);

export default NavItem;
