import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// routes
import { PATHS_MANAGE, } from '../../../routes/paths';
// components
import LoadingScreen from '../../../components/loading-screen';
//
import { useSessionContext } from '../useSessionContext';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated, isInitialized } = useSessionContext();

  if (isAuthenticated) {
    return <Navigate to={PATHS_MANAGE.dashboard} />;
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
}
