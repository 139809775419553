import { PublicAccount, Module, ModuleTemplate, TrainingPlan, UserRecordsOverview } from "@piccadilly-cloud/platform-services";

export type ActionMapType<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
  ? {
    type: Key;
  }
  : {
    type: Key;
    payload: M[Key];
  };
};

export type CacheStateType = {
  initialized: boolean;
  usersCache: PublicAccount[];
  moduleOverviewsCache: Module[];
  moduleTemplateOverviewsCache: ModuleTemplate[];
  modules: {
    cache: Record<string, Module>;
    notFound: string[],
  },
  trainingPlanCache: TrainingPlan[];
  solverCache: {
    overview: UserRecordsOverview
    fetched: boolean;
  };
};

export type CacheContextType = {
  initialized: boolean;
  moduleOverviewsCache: {
    moduleTemplateOverviews: ModuleTemplate[];
    moduleOverviews: Module[];
    methods: {
      cacheModuleOverviews: (token: string, enabledApp: string) => void;
      cacheModuleTemplateOverviews: (token: string, enabledApp: string) => void;
    };
  },
  modulesCache: {
    cache: Record<string, Module>;
    notFound: string[];
    methods: {
      cacheModule: (token: string, enabledApp: string, moduleId: string) => void,
    };
  },
  usersCache: {
    users: PublicAccount[],
    methods: {
      refreshUsersForCohortGroup: (token: string, vendor: string, cohortGroupId: string) => void;
      refreshUsers: (token: string, vendor: string) => void;
      removeUser: (userId: string) => void;
    };
  }
  trainingPlanCache: {
    trainingPlans: TrainingPlan[];
    methods: {
      refreshTrainingPlans: (token: string, enabledApp: string, cohortId?: string) => void;
    }
  },
  solverCache: {
    overview: UserRecordsOverview,
    fetched: boolean;
    methods: {
      refreshSolverOverview: (token: string, accountId: string) => void;
    }
  }
}

export enum CacheTypes {
  INITIAL = 'cache/INITIAL',
  USERS_CACHE_MERGE = 'cache/USERS_CACHE_MERGE',
  USERS_CACHE_REPLACE = 'cache/USERS_CACHE_REPLACE',
  USERS_CACHE_REMOVE = 'cache/USERS_CACHE_REMOVE',
  MODULE_OVERVIEW_CACHE_REFRESH = 'cache/MODULE_OVERVIEW_CACHE_REFRESH',
  MODULE_TEMPLATE_OVERVIEW_CACHE_REFRESH = 'cache/MODULE_TEMPLATE_OVERVIEW_CACHE_REFRESH',
  MODULE_CACHE_REFRESH = 'cache/MODULE_CACHE_REFRESH',
  TRAINING_PLAN_CACHE_REFRESH = 'cache/TRAINING_PLAN_CACHE_REFRESH',
  SOLVER_OVERVIEW_CACHE_REFRESH = 'cache/SOLVER_OVERVIEW_CACHE_REFRESH',
}

export type CachePayload = {
  [CacheTypes.INITIAL]: {},
  [CacheTypes.USERS_CACHE_MERGE]: {
    users: PublicAccount[],
  },
  [CacheTypes.USERS_CACHE_REPLACE]: {
    users: PublicAccount[],
  },
  [CacheTypes.USERS_CACHE_REMOVE]: {
    userId: string
  },
  [CacheTypes.MODULE_OVERVIEW_CACHE_REFRESH]: {
    moduleOverviews: Module[],
  },
  [CacheTypes.MODULE_TEMPLATE_OVERVIEW_CACHE_REFRESH]: {
    moduleOverviews: ModuleTemplate[],
  },
  [CacheTypes.MODULE_CACHE_REFRESH]: {
    module: Module | undefined,
    moduleId: string,
  },
  [CacheTypes.TRAINING_PLAN_CACHE_REFRESH]: {
    trainingPlans: TrainingPlan[],
  },
  [CacheTypes.SOLVER_OVERVIEW_CACHE_REFRESH]: {
    overview: UserRecordsOverview,
  }
}


export type ActionsType = ActionMapType<CachePayload>[keyof ActionMapType<CachePayload>];