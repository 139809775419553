import { UserRecord } from "@piccadilly-cloud/platform-services";
import { ActionsType, PLRecordStateType, PLRecordTypes } from "./types";


const loadRecords = (state: PLRecordStateType, action: ActionsType): PLRecordStateType => {
  if (action.type === PLRecordTypes.LOAD_RECORDS) {
    if (!action.payload.merge) {
      state.initialized = true;
      state.rawRecords = action.payload.records;
    } else {
      const recordMap: Record<string, UserRecord> = {};
      state.rawRecords.forEach((r) => { recordMap[r.id]  = r; });
      action.payload.records.forEach((r) => { recordMap[r.id] = r });

      state.initialized = true;
      state.rawRecords = Object.values(recordMap);
    }
  }
  return { ...state, };
}

const createRecord = (state: PLRecordStateType, action: ActionsType): PLRecordStateType => {
  if (action.type === PLRecordTypes.CREATE_RECORD) {
    const nextRecords = state.rawRecords.filter((r) => r.id !== action.payload.record.id);
    nextRecords.push(action.payload.record);
    state.rawRecords = [...nextRecords];
  }
  return { ...state, }
}

const invalidateRecord = (state: PLRecordStateType, action: ActionsType): PLRecordStateType => {
  if (action.type === PLRecordTypes.INVALIDATE_RECORD) {
    const nextRecords = state.rawRecords.filter((r) => r.id !== action.payload.recordId);
    state.rawRecords = nextRecords;
  }
  return { ...state, }
}

const invalidateAllRecords = (state: PLRecordStateType, action: ActionsType): PLRecordStateType => {
  if (action.type === PLRecordTypes.INVALIDATE_RECORDS) {
    let nextRecords = state.rawRecords
    action.payload.recordIds.forEach((recordId) => {
      nextRecords = nextRecords.filter((r) => r.id !== recordId)
    })
    state.rawRecords = nextRecords;
  }
  return { ...state, }
}

const reset = (state: PLRecordStateType, action: ActionsType): PLRecordStateType => {
  return {
    ...state,
    rawRecords: [],
    initialized: false,
  }
}

const reducers = {
  [PLRecordTypes.RESET]: reset,
  [PLRecordTypes.LOAD_RECORDS]: loadRecords,
  [PLRecordTypes.CREATE_RECORD]: createRecord,
  [PLRecordTypes.INVALIDATE_RECORD]: invalidateRecord,
  [PLRecordTypes.INVALIDATE_RECORDS]: invalidateAllRecords,
}

export default reducers;
