import { ActionsType, AuthStateType, SessionTypes } from "../types";

import defaults from "../defaults";
import { jwtDecode } from "../utils";

const initialReducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === SessionTypes.INITIAL) {
    let exp: number | undefined;
    try {
      const token = jwtDecode(action.payload.session.token);
      if (token && token.exp) {
        exp = token.exp;
      }
    } catch (err) {
      // noop
    }
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      isFailedToFetch: !!action.payload.isFailedToFetch,

      account: action.payload.session.account,
      appConfig: action.payload.session.appConfig,
      cohortAccounts: action.payload.session.cohortAccounts,
      sessionType: action.payload.session.sessionType,
      token: action.payload.session.token,
      records: action.payload.session.records,
      platformPermissions: action.payload.session.platformPermissions,
      exp,
    };
  }
  return { ...state, };
}

const loginReducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === SessionTypes.LOGIN) {
    let exp: number | undefined;
    const token = jwtDecode(action.payload.session.token);
    if (token && token.exp) {
      exp = token.exp;
    }
    return {
      ...state,
      isAuthenticated: true,
      account: action.payload.session.account,
      appConfig: action.payload.session.appConfig,
      cohortAccounts: action.payload.session.cohortAccounts,
      sessionType: action.payload.session.sessionType,
      token: action.payload.session.token,
      records: action.payload.session.records,
      platformPermissions: action.payload.session.platformPermissions,
      exp,
    };
  }
  return { ...state, };
}

const registerReducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === SessionTypes.REGISTER) {
    let exp: number | undefined;
    const token = jwtDecode(action.payload.session.token);
    if (token && token.exp) {
      exp = token.exp;
    }
    return {
      ...state,
      isAuthenticated: true,
      account: action.payload.session.account,
      appConfig: action.payload.session.appConfig,
      cohortAccounts: action.payload.session.cohortAccounts,
      sessionType: action.payload.session.sessionType,
      token: action.payload.session.token,
      records: action.payload.session.records,
      platformPermissions: action.payload.session.platformPermissions,
      exp,
    };
  }
  return { ...state, };
}

const logoutReducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === SessionTypes.LOGOUT) {
    // window.location.reload();
    return {
      ...state,
      isAuthenticated: false,
      ...defaults.session,
    };
  }
  return { ...state, };
}

const auth = {
  reducers: {
    [SessionTypes.INITIAL]: initialReducer,
    [SessionTypes.LOGIN]: loginReducer,
    [SessionTypes.REGISTER]: registerReducer,
    [SessionTypes.LOGOUT]: logoutReducer,
  }
};

export default auth;
