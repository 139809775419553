import { Container, Typography } from '@mui/material';
import { ForbiddenIllustration } from 'assets/illustrations';
import { MotionContainer, varBounce } from 'components/animate';
import { m } from 'framer-motion';
import React from 'react';
// import globalPermissionsByRole, { PermissionKeys } from '@piccadilly-platform-shared/permissions';


export default function PermissionsGuard(props: {
  allowed: boolean;
  children: React.ReactNode;
  fallback?: React.ReactNode;
}) {

  if (!props.allowed && !props.fallback) {
    console.log(`%cpermission denied`, 'color:red');
    return (
      <>
        <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            You do not have permission to access this page
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
      </Container>
      </>
    );
  }

  if (!props.allowed && props.fallback) {
    return (
      <>
        {props.fallback}
      </>
    )
  }

  return (
    <>
      {props.children}
    </>
  );
}