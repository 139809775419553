import React from "react";
import { SessionTypes } from "../types";
import { PublicAccount } from "@piccadilly-cloud/platform-services";

const updateCurrentAccount = (updatedAccount: PublicAccount) => {
  return async (dispatch: React.Dispatch<any>) => {
    const payload = { account: updatedAccount, }
    dispatch({ type: SessionTypes.UPDATE_ACCOUNT, payload, });
  };
}


const appConfigActions = {
  updateCurrentAccount,
};

export default appConfigActions;
