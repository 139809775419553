import React from 'react';
import { setSession } from '../utils';
import { AppRegistrationRequest, SessionTypes, UserRegistrationRequest } from "../types";
import services, { AccountRegistrationRequest, RegisterNewAppRequest, Session } from '@piccadilly-cloud/platform-services';

const login = (token: string, email: string, password: string, appId?: string) => {
  return async (dispatch: React.Dispatch<any>) => {
    let defaultHost = 'piccadillylearn';
    if (appId) {
      defaultHost = appId;
    } else if (process.env.REACT_APP_DEFAULT_HOST) {
      defaultHost = process.env.REACT_APP_DEFAULT_HOST;
    }

    const session: Session = await services.edge.session.create({
      appHost: defaultHost,
      authenticationRequest: {
        email: email.toLowerCase(),
        password: password,
        host: defaultHost,
        vendor: defaultHost,
        phoneNumber: '',
      },
      token,
    })({});
  
    setSession(session.token);
  
    dispatch({
      type: SessionTypes.LOGIN,
      payload: {
        user: null,
        session,
      },
    });
  }
};

const loginFromAccessCode = (accessCode: string, token: string) => {
  return async (dispatch: React.Dispatch<any>) => {
    const session: Session = await services.edge.session.createFromAccessCode(accessCode)({ token, })

    setSession(session.token);
  
    dispatch({
      type: SessionTypes.LOGIN,
      payload: {
        user: null,
        session,
      },
    });
  }
}

const logout = () => {
  return async (dispatch: React.Dispatch<any>) => {
    setSession(null);
    dispatch({
      type: SessionTypes.LOGOUT,
    });
  }
};


// REGISTER
const register = (request: UserRegistrationRequest) => {
  return async (dispatch: React.Dispatch<any>) => {
    const creationRequest: AccountRegistrationRequest = {
      vendor: request.invitation.vendor,
      cohortId: request.invitation.cohort,
      cohortGroupId: request.invitation.cohortGroup,
      username: request.username,
      phoneNumber: request.phoneNumber || '',
      phoneNumberVerified: false,
      email: request.email,
      emailVerified: false,
      password: request.password,
      accountType: request.invitation.accountType,
      accountRole: request.invitation.accountRole,
      demographicData: {
        age: request.age || '',
        gender: request.gender || '',
        race: request.race || '',
        gradeLevel: request.gradeLevel || '',
      },
      invitationReferenceId: request.invitation.referenceId,
    }

    const session = await services.edge.registration.registerNewUser(creationRequest)({ token: request.token, });

    setSession(session.token);

    dispatch({ type: SessionTypes.REGISTER, payload: { session, }})

  }
};


const registerWithDefaultApp = (request: AppRegistrationRequest) => {
  return async (dispatch: React.Dispatch<any>) => {
    const registrationRequest: RegisterNewAppRequest = {
      appRequest: {
        appType: 'COMMERCIAL',
        appHost: request.appHost,
        appId: request.appId,
        appName: request.appName && request.appName !== '' ? request.appName : 'Piccadilly Learn',
        owner: request.email,
      },
      accountRequest: {
        vendor: '',
        cohortId: '',
        cohortGroupId: '',
        username: request.name,
        phoneNumber: request.phoneNumber,
        phoneNumberVerified: false,
        email: request.email,
        emailVerified: false,
        password: request.password,
        accountType: 'COMMERCIAL',
        accountRole: 'ACCOUNT_HOLDER',
        demographicData: {
          age: '',
          gender: '',
          race: '',
          gradeLevel: '',
        },
        invitationReferenceId: '',
      }
    }

    const session: Session = await services.edge.registration.registerNewApp(registrationRequest)({ token: request.token, });

    setSession(session.token);

    dispatch({ type: SessionTypes.REGISTER, payload: { session, }})
  }
};

const authActions = {
  login,
  loginFromAccessCode,
  logout,
  register,
  registerWithDefaultApp,
};

export default authActions;
