import { useMemo } from 'react';
// @mui
import { SxProps, useTheme } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, IconButton, Theme } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// hooks
// config
import { HEADER, NAV } from '../../../config';
// components
import Logo from '../../../components/logoApp';
import Iconify from '../../../components/iconify';
//
import AccountPopover from './AccountPopover';
import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';
import { useSessionContext } from 'contexts/session/useSessionContext';
import ctrl from '@piccadilly-libs-backend/ctrl';

// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
  isNavHorizontal: boolean,
  isNavMini: boolean,
  isDesktop: boolean,
  isOffset: boolean,
};

export default function Header({ onOpenNav, isNavHorizontal, isNavMini, isDesktop, isOffset, }: Props) {
  const theme = useTheme();
  const session = useSessionContext();

  const permissions = useMemo(() => {
    return ctrl.permissions.getForUser(session);
  }, [session])

  const appBarStyle: SxProps<Theme> = useMemo(() => {
    return {
      boxShadow: 'none',
      height: HEADER.H_MOBILE,
      zIndex: theme.zIndex.appBar + 1,
      ...bgBlur({
        color: theme.palette.background.default,
      }),
      transition: theme.transitions.create(['height'], {
        duration: theme.transitions.duration.shorter,
      }),
      ...(isDesktop && {
        width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
        height: HEADER.H_DASHBOARD_DESKTOP,
        ...(isOffset && {
          height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
        }),
        ...(isNavHorizontal && {
          width: 1,
          bgcolor: 'background.default',
          height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
        }),
        ...(isNavMini && {
          width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
        }),
      }),
    }
  }, [isDesktop, isNavHorizontal, isNavMini, isOffset, theme.palette.background.default, theme.transitions, theme.zIndex.appBar])

  const renderContent = (
    <>
      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}

      {isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        {permissions.as_admin.nav.notifications_visible && (
          <NotificationsPopover />
        )}

        {permissions.as_admin.nav.my_cohort_visible && (
          <ContactsPopover />
        )}

        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <>
      <AppBar sx={appBarStyle} >
        <Toolbar sx={{ height: 1, px: { lg: 5 }, }} >
          {renderContent}
        </Toolbar>
      </AppBar>
    </>
  );
}
