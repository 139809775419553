// routes
import { Session } from '@piccadilly-cloud/platform-services';
import { PATHS_SOLVER, } from '../../../routes/paths';

import Iconify from 'components/iconify';
import sessionUtil from 'utils/data/sessionUtil';

// ----------------------------------------------------------------------

const getNavConfig = (session: Session) => {
  return [
    {
      subheader: 'App',
      items: [
        {
          title: 'dashboard',
          icon: <Iconify icon="ph:dna" />,
          path: PATHS_SOLVER.dashboard,
        },
        {
          title: sessionUtil.getTrainingPlanLabel(true)(session),
          icon: <><Iconify icon="ph:rocket-launch" /></>,
          path: PATHS_SOLVER.plans.root,
        },
        {
          title: `Explore ${sessionUtil.getModuleLabel(true)(session)}`,
          icon: <><Iconify icon="ph:paper-plane-tilt" /></>,
          path: PATHS_SOLVER.explore,
        },
        {
          title: 'my cohort',
          icon: <><Iconify icon="ph:users-three" /></>,
          path: PATHS_SOLVER.cohort,
        },
      ],
    },
  ];
}

export default getNavConfig;
