import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

const Loadable = (Component: ElementType) => (props: any) =>
(
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

////////////////////////////////////////////////////////////////////////////////////////////////////
// App
export const AppProfile = Loadable(lazy(() => import('../views/app/profile-view')));
export const AppConfig = Loadable(lazy(() => import('../views/app/app-config-view')));
export const AppMyCohort = Loadable(lazy(() => import('../views/app/Cohort')));
export const AppHelp = Loadable(lazy(() => import('../views/app/help')));

////////////////////////////////////////////////////////////////////////////////////////////////////
// TRACKER
export const TrackLanding = Loadable(lazy(() => import('../views/track')));
export const TrackModules = Loadable(lazy(() => import('../views/track/modules')));
export const TrackTrainingPlans = Loadable(lazy(() => import('../views/track/training-plans')));
export const TrackTrainingPlanByCohort = Loadable(lazy(() => import('../views/track/training-plans-breakdown')));
export const TrackFileManager = Loadable(lazy(() => import('../views/track/file-manager')));
export const TrackAssessmentManager = Loadable(lazy(() => import('../views/track/assessment-manager')));


////////////////////////////////////////////////////////////////////////////////////////////////////
// Training Plans
export const TrainingPlanLanding = Loadable(lazy(() => import('../views/trainingPlans/Landing')));
export const TrainingPlanManage = Loadable(lazy(() => import('../views/trainingPlans/Manage/list-training-plan')));
export const TrainingPlanManageBuilder = Loadable(lazy(() => import('../views/trainingPlans/Manage/build-training-plan')));

////////////////////////////////////////////////////////////////////////////////////////////////////

// module builder v6
export const ModuleListV6 = Loadable(lazy(() => import('../views/modules/list-modules-view')));
export const ModuleEditorV6 = Loadable(lazy(() => import('../views/modules/edit-modules-view')));
export const ModuleTemplateEditorV6 = Loadable(lazy(() => import('../views/modules/edit-modules-template-view')));
export const ModuleOrganizeV6 = Loadable(lazy(() => import('../views/modules/organize-modules-view')));


// module viewer
export const ModuleViewer = Loadable(lazy(() => import('../views/solver/Modules')));

////////////////////////////////////////////////////////////////////////////////////////////////////
// Users
export const UsersAll = Loadable(lazy(() => import('../views/manage/Users/all-users')));
export const UsersInvitations = Loadable(lazy(() => import('../views/manage/Users/invitations')));
export const UsersOrganization = Loadable(lazy(() => import('../views/manage/Users/Organization')));


////////////////////////////////////////////////////////////////////////////////////////////////////
// BUSINESS
export const BusinessLanding = Loadable(lazy(() => import('../views/business/Landing')));
export const ClientLanding = Loadable(lazy(() => import('../views/business/ClientLanding')));

export const PiccadillyLandingVariant1 = Loadable(lazy(() => import('../views/business/PiccadillyLanding/Variant1')));
export const PiccadillyLandingVariant2 = Loadable(lazy(() => import('../views/business/PiccadillyLanding/Variant2')));
export const PiccadillyLandingVariant3 = Loadable(lazy(() => import('../views/business/PiccadillyLanding/Variant3')));
export const LandingController = Loadable(lazy(() => import('../views/business/LandingController')));

export const AboutUs = Loadable(lazy(() => import('../views/business/AboutUs')));

////////////////////////////////////////////////////////////////////////////////////////////////////
// AUTH
export const AuthLogin = Loadable(lazy(() => import('../views/auth/Login')));
export const AuthForgotPassword = Loadable(lazy(() => import('../views/auth/ForgotPassword')));
export const AuthResetPassword = Loadable(lazy(() => import('../views/auth/ResetPassword')));
export const AuthLoginTextMessage = Loadable(lazy(() => import('../views/auth/LoginTextMessage')));
export const AuthLoginPasscode = Loadable(lazy(() => import('../views/auth/LoginPasscode')));
export const AuthRegister = Loadable(lazy(() => import('../views/auth/Register')));
export const AuthRegisterAccessCode = Loadable(lazy(() => import('../views/auth/RegisterAccessCode')));

export const AuthVerifyUserAddress = Loadable(lazy(() => import('../views/auth/VerifyUserAddress')));


////////////////////////////////////////////////////////////////////////////////////////////////////
// ACCOUNT HOLDER
export const AccountHolderDashboard = Loadable(lazy(() => import('../views/manage/Dashboard')));

////////////////////////////////////////////////////////////////////////////////////////////////////
// Solver
export const SolverDashboard = Loadable(lazy(() => import('../views/solver/Dashboard')));
export const SolverExplore = Loadable(lazy(() => import('../views/solver/ExploreModules')));
export const SolverContinue = Loadable(lazy(() => import('../views/solver/ContinueModules')));
export const SolverCohort = Loadable(lazy(() => import('../views/solver/MyCohort')));
export const SolverTrainingPlans = Loadable(lazy(() => import('../views/solver/TrainingPlans/List')));
export const SolverTrainingPlanView = Loadable(lazy(() => import('../views/solver/TrainingPlans/PlanView')));
export const SolverProfile = Loadable(lazy(() => import('../views/solver/MyProfile')));
export const SolverHelpView = Loadable(lazy(() => import('../views/solver/Help')));

////////////////////////////////////////////////////////////////////////////////////////////////////
// Admin
export const AdminDashboard = Loadable(lazy(() => import('../views/admin/dashboard')));
export const AdminAppManagement = Loadable(lazy(() => import('../views/admin/appManagement')));

////////////////////////////////////////////////////////////////////////////////////////////////////
// Notification
export const NotificationCenter = Loadable(lazy(() => import('../views/notifications/notificationCenter')));


////////////////////////////////////////////////////////////////////////////////////////////////////
// DEV ONLY