import { ActionsType, AuthStateType, SessionTypes } from "../types";

const updateReducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === SessionTypes.UPDATE_APP_CONFIG) {
    return {
      ...state,
      appConfig: { ...action.payload.data },
    };
  }
  return { ...state, };
}

const appConfig = {
  reducers: {
    [SessionTypes.UPDATE_APP_CONFIG]: updateReducer,
  }
};

export default appConfig;
