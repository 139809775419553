import { createContext, useEffect, useReducer, } from 'react';
import useLogger from '../../utils/useReducerLogger';
import moduleCache from './moduleCache';
import trainingPlanCache from './trainginPlanCache';
import { ActionsType, CacheContextType, CacheStateType, CacheTypes } from './types';


import usersCache from './usersCache';
import solverCache from './solverCache';

// ----------------------------------------------------------------------

const initialState: CacheStateType = {
  initialized: false,
  usersCache: [],
  moduleOverviewsCache: [],
  moduleTemplateOverviewsCache: [],
  modules: {
    cache: {},
    notFound: [],
  },
  trainingPlanCache: [],
  solverCache: {
    overview: {
      trainingPlans: {
        completed: [],
        inProgress: {},
      },
      modules: {
        completed: [],
        inProgress: {},
      },
    },
    fetched: false,
  }
}

const reducer = (state: CacheStateType, action: ActionsType) => {
  if (action.type === CacheTypes.INITIAL) {
    return {
      ...initialState,
      initialized: true,
    }
  }

  const reducers = {
    ...usersCache.reducers,
    ...moduleCache.reducers,
    ...trainingPlanCache.reducers,
    ...solverCache.reducers,
  }

  if (reducers[action.type]) {
    return reducers[action.type](state, action);
  }
  
  return state;
}

// ----------------------------------------------------------------------

export const CacheContext = createContext<CacheContextType | null>(null);

// ----------------------------------------------------------------------

type CacheProviderProps = {
  children: React.ReactNode;
};

export function CacheProvider({ children }: CacheProviderProps) {
  const [state, dispatch] = useReducer(
    // eslint-disable-next-line react-hooks/rules-of-hooks
    process.env.REACT_APP_DEBUG_REDUX === 'true' ? useLogger(reducer) : reducer, initialState
  );

  useEffect(() => {
    const initialize = () => {
      dispatch({
        type: CacheTypes.INITIAL,
        payload: {},
      });
    }
    initialize();
  }, []);

  return (
    <CacheContext.Provider
      value={{
        ...state,
        moduleOverviewsCache: {
          moduleTemplateOverviews: state.moduleTemplateOverviewsCache,
          moduleOverviews: state.moduleOverviewsCache,
          methods: {
            cacheModuleOverviews: (token: string, enabledApp: string) => moduleCache.methods.cacheModuleOverviews(token, enabledApp)(dispatch),
            cacheModuleTemplateOverviews: (token: string, enabledApp: string) => moduleCache.methods.cacheModuleTemplateOverviews(token, enabledApp)(dispatch),
          }
        },
        modulesCache: {
          cache: state.modules.cache,
          notFound: state.modules.notFound,
          methods: {
            cacheModule: (token: string, enabledApp: string, moduleId: string) => moduleCache.methods.cacheModule(state, token, enabledApp, moduleId)(dispatch),
          },
        },
        usersCache: {
          users: state.usersCache,
          methods: {
            refreshUsersForCohortGroup: (token: string, vendor: string, cohortGroupId: string) => usersCache.methods.refreshUsersForCohortGroup(token, vendor, cohortGroupId)(dispatch),
            refreshUsers: (token: string, vendor: string) => usersCache.methods.refreshUsers(token, vendor)(dispatch),
            removeUser: (userId: string) => usersCache.methods.removeUser(userId)(dispatch),
          },
        },
        trainingPlanCache: {
          trainingPlans: state.trainingPlanCache,
          methods: {
            refreshTrainingPlans: (token: string, enabledApp: string, cohortId?: string) => trainingPlanCache.methods.trainingPlans(token, enabledApp, cohortId)(dispatch),
          }
        },
        solverCache: {
          overview: state.solverCache.overview,
          fetched: state.solverCache.fetched,
          methods: {
            refreshSolverOverview: (token: string, accountId: string) => solverCache.methods.cacheSolver(token, accountId)(dispatch),
          }
        }
      }}
    >
      {children}
    </CacheContext.Provider>
  );
}