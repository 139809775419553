import { Session, Cohort, ModuleType, AccountRole } from "@piccadilly-cloud/platform-services";

const validAdminAccountRoles = ['ROOT', 'ACCOUNT_HOLDER', 'FACILITATOR', 'SINGLE_GROUP_FACILITATOR', 'SME', 'SINGLE_GROUP_SME'];

const isAuthenticated = (session: Session): boolean => {
  return session.sessionType === 'AUTHENTICATED';
}

const isRootUser = (session: Session): boolean => {
  return session.account.accountRole === 'ROOT';
}

const isGuest = (session: Session): boolean => {
  return session.account.accountRole === 'GUEST';
}

const getUsersCohort = (session: Session): Cohort => {
  const cohorts: Cohort[] = [];
  session.appConfig.cohortGroupSettings.cohortGroups.forEach((cohortGroup) => {
    cohorts.push(...cohortGroup.cohorts);
  });
  const cohort = cohorts.find((cohort) => cohort.referenceId === session.account.cohortId);
  if (cohort) {
    return cohort;
  }
  return {} as Cohort;
}

const getUsersCohortGroupLabel = (session: Session): string => {
  const cohortGroup = session.appConfig.cohortGroupSettings.cohortGroups
    .find((i) => i.referenceId === session.account.cohortGroupId);

  if (cohortGroup) {
    return cohortGroup.label;
  }
  return 'Unknown Cohort Group';
}

const getCohortFromId = (session: Session, cohortId: string,): Cohort => {
  const cohorts: Cohort[] = [];
  session.appConfig.cohortGroupSettings.cohortGroups.forEach((cohortGroup) => {
    cohorts.push(...cohortGroup.cohorts);
  });
  const cohort = cohorts.find((cohort) => cohort.referenceId === cohortId);
  if (cohort) {
    return cohort;
  }
  return {} as Cohort;
}

const getCohortGroupLabelFromId = (session: Session, cohortGroupId: string,): string => {
  const group = session.appConfig.cohortGroupSettings.cohortGroups.find((group) => group.referenceId === cohortGroupId);
  if (group) {
    return group.label;
  }
  return '';
}

const getContentType = (session: Session, contentTypeKeyword: string): ModuleType | undefined => {
  return session.appConfig.contentSettings.moduleTypes.find(type => type.keyword === contentTypeKeyword);
}

const getContentTypeLabel = (session: Session, contentTypeKeyword: string): string => {
  const contentType = session.appConfig.contentSettings.moduleTypes.find(type => type.keyword === contentTypeKeyword);
  if (contentType) {
    return contentType.label;
  }
  return '';
}

const hasValidApp = (session: Session): boolean => {
  return true;
}

const isValidAdminUser = (session: Session): boolean => {
  return validAdminAccountRoles.includes(session.account.accountRole);
}

const getUserRole = (session: Session): string => {
  return session
    .account
    .accountRole
    .split('_')
    .map((str) => {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    })
    .join(' ');
}

const getUsersRole = (role: string): string => {
  return role
    .split('_')
    .map((str) => {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    })
    .join(' ');
}

const isUserVisible = (role: AccountRole): boolean => {
  if (role === 'GUEST') {
    return false;
  }
  return true;
}

const isUserSolver = (role: AccountRole): boolean => {
  if (role === 'ROOT') {
    return false;
  }
  if (role === 'FACILITATOR') {
    return false;
  }
  if (role === 'SINGLE_GROUP_FACILITATOR') {
    return false;
  }
  if (role === 'SINGLE_COHORT_FACILITATOR') {
    return false;
  }
  return true;
}

const getCohortOptionLabel = (session: Session, option: string): string => {
  let cohortLabel = '';
  session.appConfig.cohortGroupSettings.cohortGroups.forEach((cohortGroup) => {
    cohortGroup.cohorts.forEach((cohort) => {
      if (cohort.referenceId === option) {
        cohortLabel = `${cohortGroup.label}/${cohort.label}`
      }
    });
  });
  return cohortLabel;
}

const getCohortGroupFromCohortId = (session: Session, cohortId: string): string => {
  let cohortGroupLabel = '';
  session.appConfig.cohortGroupSettings.cohortGroups.forEach((cohortGroup) => {
    cohortGroup.cohorts.forEach((cohort) => {
      if (cohort.referenceId === cohortId) {
        cohortGroupLabel = cohortGroup.referenceId;
      }
    });
  });
  return cohortGroupLabel;
}

const getCohortGroupLabelFromCohortId = (session: Session, cohortId: string): string => {
  let cohortGroupLabel = '';
  session.appConfig.cohortGroupSettings.cohortGroups.forEach((cohortGroup) => {
    cohortGroup.cohorts.forEach((cohort) => {
      if (cohort.referenceId === cohortId) {
        cohortGroupLabel = cohortGroup.label;
      }
    });
  });
  return cohortGroupLabel;
}

const getCohortIdsForCohortGroup = (session: Session, cohortGroupId: string): string[] => {
  const cohortGroup = session.appConfig.cohortGroupSettings.cohortGroups.find(t => t.referenceId === cohortGroupId);
  const restrictedCohorts: string[] = [];
  if (cohortGroup) {
    restrictedCohorts.push(...cohortGroup.cohorts.map((c) => c.referenceId));
  }
  return restrictedCohorts;
}

const getTrainingPlanLabel = (plural: boolean, lowerCase?: boolean) => {
  return (session: Session): string => {
    if (plural) {
      if (lowerCase) {
        return session.appConfig.contentSettings?.trainingPlanLabelPlural?.toLowerCase() || '_training_plan_label_plural_lower';
      }
      return session.appConfig.contentSettings?.trainingPlanLabelPlural || '_training_plan_label_plural_upper';
    } else {
      if (lowerCase) {
        return session.appConfig.contentSettings?.trainingPlanLabelSingle?.toLowerCase() || '_training_plan_label_single_lower';
      }
      return session.appConfig.contentSettings?.trainingPlanLabelSingle || '_training_plan_label_single_upper';
    }
  }
}

const getModuleLabel = (plural: boolean, lowerCase?: boolean) => {
  return (session: Session): string => {
    if (plural) {
      if (lowerCase) {
        return session.appConfig.contentSettings?.moduleLabelPlural?.toLowerCase() || '_module_label_plural_lower';
      }
      return session.appConfig.contentSettings?.moduleLabelPlural || '_module_label_plural_upper';
    } else {
      if (lowerCase) {
        return session.appConfig.contentSettings?.moduleLabelSingle?.toLowerCase() || '_module_label_single_lower';
      }
      return session.appConfig.contentSettings?.moduleLabelSingle || '_module_label_single_upper';
    }
  }
}

const getDefaultSessionType = (): 'NONPROFIT' | 'COMMERCIAL' => {
  const defaultHost = process.env.REACT_APP_DEFAULT_HOST;
  if (defaultHost && defaultHost === 'accelerus') {
    return 'NONPROFIT';
  }
  return 'COMMERCIAL';
}

const sessionUtil = {
  isGuest,
  hasValidApp,
  isAuthenticated,
  isRootUser,
  getUsersCohort,
  getContentType,
  getContentTypeLabel,
  isValidAdminUser,
  getCohortFromId,
  getUsersCohortGroupLabel,
  getCohortGroupLabelFromId,
  getUserRole,
  getUsersRole,
  isUserVisible,
  isUserSolver,
  getCohortOptionLabel,
  getCohortGroupFromCohortId,
  getCohortGroupLabelFromCohortId,
  getTrainingPlanLabel,
  getModuleLabel,
  getCohortIdsForCohortGroup,
  getDefaultSessionType,
}

export default sessionUtil;
