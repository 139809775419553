import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
// auth
import { useSessionContext } from '../../../contexts/session/useSessionContext';
// routes
import { PATHS_SOLVER, } from '../../../routes/paths';
// components
import { CustomAvatar } from '../../../components/custom-avatar';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const { session } = useSessionContext();

  const initials = (s : string): string => {
    return s.split(' ').map((v) => {
      if (v.length >= 1) {
        return v[0].toUpperCase()
      }
      return '';
    }).join('');
  }

  const normalize = (s: string): string => {
    return s
    .split('_')
    .map((str) => {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    })
    .join(' ');
  }

  return (
    <Link to={PATHS_SOLVER.profile} component={RouterLink} underline="none" color="inherit">
      <StyledRoot>
        <CustomAvatar sx={{ fontSize: 'small', }}>
          {initials(session?.account.username || '')}
        </CustomAvatar>

        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Typography variant="subtitle2" noWrap>
            {session?.account.username}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
            {normalize(session?.account.accountRole || '')}
          </Typography>
        </Box>
      </StyledRoot>
    </Link>
  );
}
