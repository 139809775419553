// routes
import { PATHS_BUILD, PATHS_MANAGE, PATHS_PLAN, PATHS_SOLVER, PATHS_USER, } from '../../../routes/paths';

import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import AppSettingsAltTwoToneIcon from '@mui/icons-material/AppSettingsAltTwoTone';
import ManageAccountsTwoToneIcon from '@mui/icons-material/ManageAccountsTwoTone';
import StackedLineChartTwoToneIcon from '@mui/icons-material/StackedLineChartTwoTone';
import ForwardTwoToneIcon from '@mui/icons-material/ForwardTwoTone';
import ExploreTwoToneIcon from '@mui/icons-material/ExploreTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import BuildCircleTwoToneIcon from '@mui/icons-material/BuildCircleTwoTone';
import PreviewTwoToneIcon from '@mui/icons-material/PreviewTwoTone';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone';
import InventoryTwoToneIcon from '@mui/icons-material/InventoryTwoTone';
import LocalCafeOutlinedIcon from '@mui/icons-material/LocalCafeOutlined';
import Iconify from 'components/iconify';

// ----------------------------------------------------------------------
const navConfig = [
  {
    subheader: 'App',
    items: [
      {
        title: 'dashboard',
        icon: <DashboardTwoToneIcon />,
        path: PATHS_MANAGE.dashboard,
      },
      {
        title: 'training plans',
        icon: <Iconify icon="mdi:teach-poll" />,
        path: PATHS_PLAN.root,
        children: [
          {
            title: 'Manage',
            path: PATHS_PLAN.manage,
            icon: <Iconify icon="fluent-mdl2:build-queue-new" />,
          },
        ]
      },
      {
        title: 'modules',
        path: PATHS_BUILD.root,
        icon: <BuildCircleTwoToneIcon />,
        children: [
          {
            title: 'progress',
            path: PATHS_BUILD.progress,
            icon: <StackedLineChartTwoToneIcon />,
          },
          {
            title: 'builder',
            path: '#',
            icon: <BuildCircleTwoToneIcon />,
            children: [
              { title: 'modules', path: PATHS_BUILD.modules, },
              { title: 'templates', path: PATHS_BUILD.templates, },
              { title: 'organization', path: PATHS_BUILD.organization, },
            ]
          },
          {
            title: 'preview',
            path: PATHS_BUILD.preview,
            icon: <PreviewTwoToneIcon />,
          },
        ]
      },
      {
        title: 'users',
        path: '#',
        icon: <ManageAccountsTwoToneIcon />,
        children: [
          {
            title: 'invitations',
            path: '/manage/users/invitations',
            icon: <SendTwoToneIcon />,
          },
          {
            title: 'all users',
            path: '/manage/users/default',
            icon: <GroupTwoToneIcon />,
          },
          {
            title: 'organization',
            path: '/manage/users/organization',
            icon: <InventoryTwoToneIcon />,
          },
        ]
      },
      {
        title: 'solvers',
        icon: <ExploreTwoToneIcon />,
        path: '/solver',
        children: [
          {
            title: 'dashboard',
            icon: <DashboardTwoToneIcon />,
            path: PATHS_SOLVER.dashboard,
          },
          {
            title: 'continue modules',
            icon: <ForwardTwoToneIcon />,
            path: PATHS_SOLVER.continue,
          },
          {
            title: 'explore modules',
            icon: <ExploreTwoToneIcon />,
            path: PATHS_SOLVER.explore,
          },
          {
            title: 'my cohort',
            icon: <LocalCafeOutlinedIcon />,
            path: PATHS_SOLVER.cohort,
          },
        ]
      },
      {
        title: 'app',
        icon: <DashboardTwoToneIcon />,
        path: '#',
        children: [
          {
            title: 'app config',
            path: PATHS_MANAGE.app,
            icon: <AppSettingsAltTwoToneIcon />,
          },
          {
            title: 'profile',
            path: PATHS_USER.profile,
            icon: <AccountBoxTwoToneIcon />,
          }
        ]
      },
      // {
      //   title: 'my cohort',
      //   icon:  <></>, //<Diversity2TwoToneIcon />,
      //   path: '',
      // },
    ],
  },
];

export default navConfig;
