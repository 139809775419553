import { createContext, useEffect, useReducer, useCallback } from 'react';
import { isValidToken, setSession } from './utils';
import { ActionsType, AppRegistrationRequest, AuthStateType, SessionContextType, SessionTypes, UserRegistrationRequest } from './types';
import useLogger from 'utils/useReducerLogger';
import defaults from './defaults';

import auth from './reducers/auth';
import account from './reducers/account';
import appConfig from './reducers/appConfig';

import authActions from './actions/auth';
import appConfigActions from './actions/appConfig';
import accountActions from './actions/account';
import services, { BrandingSettings, Cohort, CohortGroups, ContentSettings, LandingSettings, ModuleType, PublicAccount, SecuritySettings, Session, Settings, UserSettings } from '@piccadilly-cloud/platform-services';

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  isFailedToFetch: false,

  platformPermissions: defaults.platformPermissions,
  account: defaults.account,
  appConfig: defaults.appConfig,
  cohortAccounts: [],
  records: [],
  sessionType: 'UNAUTHENTICATED',
  token: '',

};

const reducer = (state: AuthStateType, action: ActionsType) => {
  const reducers = {
    ...auth.reducers,
    ...account.reducers,
    ...appConfig.reducers
  }
  if (reducers[action.type]) {
    return reducers[action.type](state, action);
  }
  return state;
};

// ----------------------------------------------------------------------

export const SessionContext = createContext<SessionContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(
    // eslint-disable-next-line react-hooks/rules-of-hooks
    process.env.REACT_APP_DEBUG_REDUX === 'true' ? useLogger(reducer) : reducer, initialState
  );

  const initialize = useCallback(async () => {
    // let defaultHost = 'piccadillylearn';
    // if (process.env.REACT_APP_DEFAULT_HOST) {
    //   defaultHost = process.env.REACT_APP_DEFAULT_HOST;
    // }
    let currentHostname = window.location.hostname;
    currentHostname = currentHostname.replace('www.', '');

    try {
      const accessToken = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : '';
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const session: Session = await services.edge.session.create({
          appHost: currentHostname,
          token: accessToken,
          authenticationRequest: undefined
        })({});

        dispatch({
          type: SessionTypes.INITIAL,
          payload: {
            isAuthenticated: true,
            session,
          },
        });
      } else {
        const session: Session = await services.edge.session.create({
          appHost: currentHostname,
          token: '',
          authenticationRequest: undefined
        })({});

        dispatch({
          type: SessionTypes.INITIAL,
          payload: {
            isAuthenticated: false,
            session: session,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: SessionTypes.INITIAL,
        payload: {
          isAuthenticated: false,
          isFailedToFetch: true,
          session: defaults.session,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <SessionContext.Provider
      value={{
        ...state,
        session: {
          account: state.account,
          appConfig: state.appConfig,
          cohortAccounts: state.cohortAccounts,
          sessionType: state.sessionType,
          token: state.token,
        },
        method: 'jwt',
        login: (token: string, email: string, password: string, appId?: string) => authActions.login(token, email, password, appId)(dispatch),
        loginFromAccessCode: (accessCode: string, token: string) => authActions.loginFromAccessCode(accessCode, token)(dispatch),
        logout: () => authActions.logout()(dispatch),
        register: (request: UserRegistrationRequest) =>
          authActions.register(request)(dispatch),
        registerWithDefaultApp: (request: AppRegistrationRequest) =>
          authActions.registerWithDefaultApp(request)(dispatch),
        accountHooks: {
          updateCurrentAccount: (updatedAccount: PublicAccount) => accountActions.updateCurrentAccount(updatedAccount)(dispatch),
        },
        appConfigHooks: {
          updateSettings: (token: string, appId: string, settings: Settings) => 
            appConfigActions.updateSettings(token, appId, settings)(dispatch),
          updateContentSettings: (token: string, appId: string, settings: ContentSettings) => 
            appConfigActions.updateContentSettings(token, appId, settings)(dispatch),
          updateSecuritySettings: (token: string, appId: string, settings: SecuritySettings) =>
            appConfigActions.updateSecuritySettings(token, appId, settings)(dispatch),
          updateBrandingSettings: (token: string, appId: string, settings: BrandingSettings) => 
            appConfigActions.updateBrandingSettings(token, appId, settings)(dispatch),
          updateLandingSettings: (token: string, appId: string, settings: LandingSettings) => 
            appConfigActions.updateLandingSettings(token, appId, settings)(dispatch),
          updateUserSettings: (token: string, appId: string, settings: UserSettings) => 
            appConfigActions.updateUserSettings(token, appId, settings)(dispatch),
          updateCohortGroup: (data: CohortGroups, appId: string, token: string) =>
            appConfigActions.updateCohortGroup(data, appId, token)(dispatch),
          deleteCohortGroup: (referenceId: string, appId: string, token: string) =>
            appConfigActions.deleteCohortGroup(referenceId, appId, token)(dispatch),
          createCohort: (data: Cohort, cohortGroupId: string, vendor: string, token: string) =>
            appConfigActions.createCohort(data, cohortGroupId, vendor, token)(dispatch),
          updateCohort: (data: Cohort, cohortGroupId: string, vendor: string, token: string) =>
            appConfigActions.updateCohort(data, cohortGroupId, vendor, token)(dispatch),
          deleteCohort: (cohortId: string, cohortGroupId: string, vendor: string, token: string) =>
            appConfigActions.deleteCohort(cohortId, cohortGroupId, vendor, token)(dispatch),
          updateModuleType: (item: ModuleType, appReferenceId: string, token: string) =>
            appConfigActions.updateModuleType(item, appReferenceId, token)(dispatch),
          deleteModuleType: (dynamicContentTypeId: string, appReferenceId: string, token: string) =>
            appConfigActions.deleteModuleType(dynamicContentTypeId, appReferenceId, token)(dispatch),
        }
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}
