import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../contexts/session/guards/AuthGuard';
import GuestGuard from '../contexts/session/guards/GuestGuard';
// layouts
import SolverDashboardLayout from 'layouts/solver';
import DashboardLayout from '../layouts/facilitator';
import MainLayout from '../layouts/main';
import ModuleLayout from '../layouts/module';


import { Session } from '@piccadilly-cloud/platform-services';
import PermissionsGuard from '@piccadilly-libs-frontend/permissions/PermissionsGuard';
import FacilitatorGuard from 'contexts/session/guards/FacilitatorGuard';
import RootGuard from 'contexts/session/guards/RootGuard';
import SessionGuard from 'contexts/session/guards/SessionGuard';
import SolverGuard from 'contexts/session/guards/SolverGuard';
import {
  AboutUs,

  AccountHolderDashboard,
  AdminAppManagement,
  AdminDashboard,
  AppConfig,
  AppHelp,
  AppMyCohort,
  AppProfile,
  AuthForgotPassword,
  AuthLogin,
  AuthLoginPasscode,
  AuthLoginTextMessage,
  AuthRegister,
  AuthRegisterAccessCode,
  AuthResetPassword,
  AuthVerifyUserAddress,

  ClientLanding,
  LandingController,
  ModuleEditorV6,
  ModuleListV6,
  ModuleOrganizeV6,
  ModuleTemplateEditorV6,
  ModuleViewer,
  NotificationCenter,
  SolverCohort,
  SolverContinue,
  SolverDashboard,
  SolverExplore,
  SolverHelpView,
  SolverProfile,
  SolverTrainingPlanView,
  SolverTrainingPlans,
  TrackAssessmentManager,
  TrackFileManager,
  TrackLanding,
  TrackModules,
  TrackTrainingPlanByCohort,
  TrackTrainingPlans,
  TrainingPlanLanding,
  TrainingPlanManage,
  TrainingPlanManageBuilder,
  UsersAll,
  UsersInvitations,
  UsersOrganization,
} from './elements';


// ----------------------------------------------------------------------

export default function Router(props: { session: Session }) {
  const permissions = props.session.platformPermissions;
  const guestRoutes = [
    {
      element: <SessionGuard><MainLayout /></SessionGuard>,
      children: [
        { element: <GuestGuard><LandingController /></GuestGuard>, index: true },
        { path: ':appHost', element: <GuestGuard><ClientLanding /></GuestGuard>, index: true },
        { path: 'login', element: <GuestGuard><AuthLogin /></GuestGuard> },
        { path: 'register', element: (<GuestGuard><AuthRegister /></GuestGuard>), },
        { path: 'register/access-code', element: (<GuestGuard><AuthRegisterAccessCode /></GuestGuard>), },
        { path: 'verify/user', element: (<AuthVerifyUserAddress />), },
        { path: 'about-us', element: <GuestGuard><AboutUs /></GuestGuard>, index: true },
      ],
    },
    {
      path: 'auth',
      element: (
        <SessionGuard>
          <GuestGuard>
            <MainLayout />
          </GuestGuard>
        </SessionGuard>
      ),
      children: [
        { path: 'login', element: <AuthLogin /> },
        { path: 'forgot-password', element: (<AuthForgotPassword />), },
        { path: 'reset-password', element: (<AuthResetPassword />), },
        { path: 'login/text-message', element: (<AuthLoginTextMessage />), },
        { path: 'login/passcode', element: (<AuthLoginPasscode />), },
        { path: 'register', element: (<AuthRegister />), },
        { path: 'register/access-code', element: (<AuthRegisterAccessCode />), },
      ],
    },
  ];

  const adminRoutes = [
    {
      path: 'dashboard',
      element: (
        <SessionGuard>
          <AuthGuard>
            <FacilitatorGuard>
              <DashboardLayout />
            </FacilitatorGuard>
          </AuthGuard>
        </SessionGuard>
      ),
      children: [
        { path: '', element: <PermissionsGuard allowed={permissions.as_admin.nav.dashboard_visible}><AccountHolderDashboard /></PermissionsGuard> },
      ]
    },
    {
      path: 'track',
      element: (
        <SessionGuard>
          <AuthGuard>
            <FacilitatorGuard>
              <DashboardLayout />
            </FacilitatorGuard>
          </AuthGuard>
        </SessionGuard>
      ),
      children: [
        { path: '', element: <PermissionsGuard allowed={permissions.as_admin.nav.training_plan_track_visible}><TrackLanding /></PermissionsGuard> },
        { path: 'file-manager', element: <PermissionsGuard allowed={permissions.as_admin.nav.training_plan_track_visible}><TrackFileManager /></PermissionsGuard> },
        { path: 'assessment-manager', element: <PermissionsGuard allowed={permissions.as_admin.nav.training_plan_track_visible}><TrackAssessmentManager /></PermissionsGuard> },
        { path: 'modules', element: <PermissionsGuard allowed={permissions.as_admin.nav.training_plan_track_visible}><TrackModules /></PermissionsGuard> },
        { path: 'training-plans', element: <PermissionsGuard allowed={permissions.as_admin.nav.training_plan_track_visible}><TrackTrainingPlans /></PermissionsGuard> },
        { path: 'training-plans/:planId/:cohortId', element: <PermissionsGuard allowed={permissions.as_admin.nav.training_plan_track_visible}><TrackTrainingPlanByCohort /></PermissionsGuard>, },
      ],
    },
    {
      path: 'preview',
      element: (
        <SessionGuard>
          <AuthGuard>
            <FacilitatorGuard>
              <DashboardLayout />
            </FacilitatorGuard>
          </AuthGuard>
        </SessionGuard>
      ),
      children: [
        { path: 'training-plans', element: <PermissionsGuard allowed={permissions.as_admin.training_plans_can_view}><SolverTrainingPlans /></PermissionsGuard> },
        { path: 'training-plans/:planId', element: <PermissionsGuard allowed={permissions.as_admin.training_plans_can_view}><SolverTrainingPlanView /></PermissionsGuard> },
        { path: 'module/:moduleId', element: <PermissionsGuard allowed={permissions.as_admin.training_plans_can_view}><ModuleViewer /></PermissionsGuard> },
        { path: 'module/:moduleId/:moduleStepId', element: <PermissionsGuard allowed={permissions.as_admin.training_plans_can_view}><ModuleViewer /></PermissionsGuard> },
      ]
    },
    {
      path: 'plan',
      element: (
        <SessionGuard>
          <AuthGuard>
            <FacilitatorGuard>
              <DashboardLayout />
            </FacilitatorGuard>
          </AuthGuard>
        </SessionGuard>
      ),
      children: [
        {
          path: '',
          element:
            <PermissionsGuard
              allowed={permissions.as_admin.nav.training_plan_manage_visible}
              fallback={(<Navigate to="/plan/track" />)}
            >
              <TrainingPlanLanding />
            </PermissionsGuard>,
        },
        { path: 'manage', element: <PermissionsGuard allowed={permissions.as_admin.nav.training_plan_manage_visible}><TrainingPlanManage /></PermissionsGuard>, },
        { path: 'manage/builder', element: <PermissionsGuard allowed={permissions.as_admin.nav.training_plan_manage_visible}><TrainingPlanManageBuilder /></PermissionsGuard>, },
        { path: 'manage/builder/:trainingPlanId', element: <PermissionsGuard allowed={permissions.as_admin.nav.training_plan_manage_visible}><TrainingPlanManageBuilder /></PermissionsGuard>, },
      ],
    },
    {
      path: 'build',
      element: (
        <SessionGuard>
          <AuthGuard>
            <FacilitatorGuard>
              <DashboardLayout />
            </FacilitatorGuard>
          </AuthGuard>
        </SessionGuard>
      ),
      children: [
        { path: '', element: <PermissionsGuard allowed={permissions.as_admin.nav.module_visible}><ModuleListV6 /></PermissionsGuard>, },
        { path: 'list', element: <PermissionsGuard allowed={permissions.as_admin.nav.module_visible}><ModuleListV6 /></PermissionsGuard>, },
        { path: 'edit', element: <PermissionsGuard allowed={permissions.as_admin.nav.module_manage_visible}><ModuleEditorV6 /></PermissionsGuard>, },
        { path: 'edit/:moduleId', element: <PermissionsGuard allowed={permissions.as_admin.nav.module_manage_visible}><ModuleEditorV6 /></PermissionsGuard>, },
        { path: 'edit-template', element: <PermissionsGuard allowed={permissions.as_admin.nav.module_manage_visible}><ModuleTemplateEditorV6 /></PermissionsGuard>, },
        { path: 'edit-template/:moduleId', element: <PermissionsGuard allowed={permissions.as_admin.nav.module_manage_visible}><ModuleTemplateEditorV6 /></PermissionsGuard>, },
        { path: 'organization', element: <PermissionsGuard allowed={permissions.as_admin.nav.module_manage_visible}><ModuleOrganizeV6 /></PermissionsGuard>, },
      ]
    },
    {
      path: 'manage',
      element: (
        <SessionGuard>
          <AuthGuard>
            <FacilitatorGuard>
              <DashboardLayout />
            </FacilitatorGuard>
          </AuthGuard>
        </SessionGuard>
      ),
      children: [
        { path: '', element: <Navigate to="/manage/users/default" />, },
        {
          path: 'users',
          children: [
            { path: 'default', element: <PermissionsGuard allowed={permissions.as_admin.nav.users_all_users_visible}><UsersAll /></PermissionsGuard> },
            { path: 'invitations', element: <PermissionsGuard allowed={permissions.as_admin.nav.users_invitations_visible}><UsersInvitations /></PermissionsGuard> },
            { path: 'invitations/:invitationId', element: <PermissionsGuard allowed={permissions.as_admin.nav.users_invitations_visible}><UsersInvitations /></PermissionsGuard> },
            { path: 'organization', element: <PermissionsGuard allowed={permissions.as_admin.nav.users_organize_visible}><UsersOrganization /></PermissionsGuard> },
          ]
        }
      ]
    },
    {
      path: 'app',
      element: (
        <SessionGuard>
          <AuthGuard>
            <FacilitatorGuard>
              <DashboardLayout />
            </FacilitatorGuard>
          </AuthGuard>
        </SessionGuard>
      ),
      children: [
        { path: '', element: <PermissionsGuard allowed={permissions.as_admin.nav.app_settings_visible} fallback={<Navigate to="/app/profile" />}><Navigate to="/app/config" /></PermissionsGuard> },
        { path: 'config', element: <PermissionsGuard allowed={permissions.as_admin.nav.app_settings_visible}><AppConfig /></PermissionsGuard> },
        { path: 'profile', element: <PermissionsGuard allowed={permissions.as_admin.nav.app_profile_visible}><AppProfile /></PermissionsGuard> },
        { path: 'cohort', element: <PermissionsGuard allowed={permissions.as_admin.nav.my_cohort_visible}><AppMyCohort /></PermissionsGuard> },
        { path: 'help', element: <PermissionsGuard allowed={true}><AppHelp /></PermissionsGuard> },
      ]
    },
    {
      path: '/notification',
      element: (
        <SessionGuard>
          <AuthGuard>
            <FacilitatorGuard>
              <DashboardLayout />
            </FacilitatorGuard>
          </AuthGuard>
        </SessionGuard>
      ),
      children: [
        { path: '', element: <PermissionsGuard allowed={permissions.as_admin.nav.notifications_visible}><NotificationCenter /></PermissionsGuard>, }
      ],
    },
    {
      path: '/user',
      element: (
        <SessionGuard>
          <AuthGuard>
            <FacilitatorGuard>
              <DashboardLayout />
            </FacilitatorGuard>
          </AuthGuard>
        </SessionGuard>
      ),
      children: [
        { path: 'profile', element: <AppProfile /> },
      ],
    },
  ];

  const solverRoutes = [
    {
      path: 'solver',
      element: (
        <SessionGuard>
          <AuthGuard>
            <SolverGuard>
              <SolverDashboardLayout />
            </SolverGuard>
          </AuthGuard>
        </SessionGuard>
      ),
      children: [
        { path: 'dashboard', element: <SolverDashboard /> },
        { path: 'profile', element: <SolverProfile /> },
        { path: 'help', element: <SolverHelpView /> },
        { path: 'explore', element: <SolverExplore /> },
        { path: 'continue', element: <SolverContinue /> },
        { path: 'cohort', element: <SolverCohort /> },
        { path: 'plans', element: <SolverTrainingPlans /> },
        { path: 'plans/:planId', element: <SolverTrainingPlanView /> },
      ]
    },
    {
      path: 'module',
      element: (
        <SessionGuard>
          <AuthGuard>
            <SolverGuard>
              <ModuleLayout />
            </SolverGuard>
          </AuthGuard>
        </SessionGuard>
      ),
      children: [
        { path: ':moduleId', element: <ModuleViewer /> },
        { path: ':moduleId/:moduleStepId', element: <ModuleViewer /> },
      ]
    },
  ];

  const rootRoutes = [
    {
      path: 'admin',
      element: (
        <SessionGuard>
          <AuthGuard>
            <RootGuard>
              <DashboardLayout />
            </RootGuard>
          </AuthGuard>
        </SessionGuard>
      ),
      children: [
        { path: 'dashboard', element: <AdminDashboard />, },
        { path: 'app-management', element: <AdminAppManagement />, },
      ]
    },
  ]

  return useRoutes([
    ...guestRoutes,
    ...adminRoutes,
    ...solverRoutes,
    ...rootRoutes,
  ]);
}
