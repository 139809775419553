import React from 'react';

import ThemeProvider from './theme';
import { ThemeSettings } from './components/settings';
import SnackbarProvider from './components/snackbar';
import { MotionLazyContainer } from './components/animate';

import './theme/style.module.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import 'modules/PiccUiKit/style/flexHelpers.css';
import 'modules/PiccUiKit/style/stepHelpers.css';
import 'modules/PiccUiKit/style/textColors.css';
import 'modules/PiccUiKit/style/misc.css';
import 'modules/PiccUiKit/style/colors.css';

import 'modules/PiccUiKit/style/vol/color.css';
import 'modules/PiccUiKit/style/vol/flex.css';
import 'modules/PiccUiKit/style/vol/sizing.css';
import 'modules/PiccUiKit/style/vol/spacing.css';

import Router from './routes';
import { useSessionContext } from 'contexts/session/useSessionContext';

function App() {
  const session = useSessionContext();
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <SnackbarProvider>
            <Router session={session}/>
          </SnackbarProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}

export default App;
