import { UserRecord, UserRecordRequest, UserRecordAnnotation } from "@piccadilly-cloud/platform-services";

export type ActionMapType<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
  ? {
    type: Key;
  }
  : {
    type: Key;
    payload: M[Key];
  };
};

export type PLRecordContextType = {
  initialized: boolean,
  rawRecords: UserRecord[];
  utils: {
    getCompletePlanSections: (accountId: string, planId: string) => UserRecord[],
    getCompletedModuleSteps: (accountId: string, moduleId: string, planId?: string, planSectionId?: string) => UserRecord[],
    getCompletedModules: (accountId: string, moduleId: string, planId?: string, planSectionId?: string) => UserRecord[],
    moduleFirstStartedAt: (accountId: string, moduleId: string, planId?: string, planSectionId?: string) => Date | undefined,
    getCompletePlanSectionsByCohort: (cohortId: string, planId: string) => UserRecord[],
    getCompletedModuleStepsByCohort: (cohortId: string, moduleId: string, planId?: string, planSectionId?: string) => UserRecord[],
    getCompletedModulesByCohort: (cohortId: string, moduleId: string, planId?: string, planSectionId?: string) => UserRecord[],
    moduleFirstStartedAtByCohort: (cohortId: string, moduleId: string, planId?: string, planSectionId?: string) => Date | undefined,
  },

  reset: () => void;
  loadRecords: (nextRecords: UserRecord[], merge?: boolean,) => void,
  createRecord: (token: string, recordRequest: UserRecordRequest) => Promise<UserRecord>,
  createPreviewRecord: (recordRequest: UserRecordRequest) => Promise<UserRecord>,
  invalidateRecord: (token: string, appId: string, recordId: string) => Promise<void>,
  invalidateRecordByAdmin: (token: string, appId: string, recordId: string) => void,
  invalidateAllRecordsByAdmin: (token: string, appId: string, recordIds: string[]) => void,
  updateAnnotation: (token: string, appId: string, recordId: string, annotation: UserRecordAnnotation) => void,
}

export enum PLRecordTypes {
  RESET = 'plrecord/RESET',
  LOAD_RECORDS = 'plrecord/LOAD_RECORDS',
  CREATE_RECORD = 'plrecord/CREATE_RECORD',
  INVALIDATE_RECORD = 'plrecord/INVALIDATE_RECORD',
  INVALIDATE_RECORDS = 'plrecord/INVALIDATE_RECORDS',
}

type PLRecordPayload = {
  [PLRecordTypes.LOAD_RECORDS]: {
    records: UserRecord[];
    merge?: boolean;
  },
  [PLRecordTypes.CREATE_RECORD]: {
    record: UserRecord;
  },
  [PLRecordTypes.INVALIDATE_RECORD]: {
    recordId: string;
  },
  [PLRecordTypes.INVALIDATE_RECORDS]: {
    recordIds: string[];
  },
}

export type ActionsType = ActionMapType<PLRecordPayload>[keyof ActionMapType<PLRecordPayload>];

export type PLRecordStateType = {
  initialized: boolean;
  rawRecords: UserRecord[];
}
