// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_USER = '/user';

const ROOTS_MANAGE = '/manage';
const ROOTS_TRACK = '/track';
const ROOTS_PREVIEW = '/preview';
const ROOTS_APP = '/app';
const ROOTS_PLAN = '/plan';
const ROOTS_BUILD = '/build';
const ROOTS_SOLVER = '/solver';
const ROOTS_AUTH = '/auth';
const ROOTS_MODULE = '/module';
const ROOTS_ADMIN = '/admin';
const ROOTS_NOTIFICATION = '/notification';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: '/login',
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  textMessage: path(ROOTS_AUTH, '/login/text-message'),
  passcode: path(ROOTS_AUTH, '/login/passcode'),
  register: path(ROOTS_AUTH, '/register'),
  registerPasscode: path(ROOTS_AUTH, '/register/access-code'),
};

export const PATHS_ADMIN = {
  root: ROOTS_ADMIN,
  dashboard: path(ROOTS_ADMIN, '/dashboard'),
  appManagement: path(ROOTS_ADMIN, '/app-management'),
};

export const PATHS_MODULE = {
  root: ROOTS_MODULE,
  moduleRoot: (id: string) => path(ROOTS_MODULE, `/${id}`),
  moduleRootForExperience: (id: string, experienceType?: string, experienceId?: string) => {
    let basePath = path(ROOTS_MODULE, `/${id}`);
    if (experienceType && experienceId) {
      basePath = `${basePath}?experience=${encodeURIComponent(experienceType)}&id=${encodeURIComponent(experienceId)}`;
    } else if (experienceType) {
      basePath = `${basePath}?experience=${encodeURIComponent(experienceType)}`
    }
    return basePath;
  },
  moduleSubstepForExperience: (id: string, substepId: string, initialStep?: string, experienceType?: string, experienceId?: string) => {
    let basePath = path(ROOTS_MODULE, `/${id}/${substepId}`);
    const pathParams = [];
    if (initialStep) {
      pathParams.push(`initialStep=${encodeURIComponent(initialStep)}`)
    }
    if (experienceType) {
      pathParams.push(`experience=${encodeURIComponent(experienceType)}`);
    }
    if (experienceId) {
      pathParams.push(`id=${encodeURIComponent(experienceId)}`);
    }
    if (pathParams.length !== 0) {
      basePath = `${basePath}?${pathParams.join('&')}`
    }
    return basePath;
  },
}

export const PATHS_NOTIFICATION = {
  root: ROOTS_NOTIFICATION,
}

export const PATHS_APP = {
  root: ROOTS_APP,
  dashboard: path(ROOTS_APP, '/dashboard'),
  continue: path(ROOTS_APP, '/continue'),
  explore: path(ROOTS_APP, '/explore'),
  profile: path(ROOTS_APP, '/profile'),
  cohort: path(ROOTS_APP, '/cohort'),
  app: path(ROOTS_APP, '/'),
  help: path(ROOTS_APP, '/help'),
};

export const PATHS_USER = {
  root: ROOTS_USER,
  profile: path(ROOTS_USER, '/profile'),
};

export const PATHS_MANAGE = {
  root: ROOTS_MANAGE,
  dashboard: '/dashboard',
  app: path(ROOTS_MANAGE, '/app'),
  users: {
    all: path(ROOTS_MANAGE, '/users/default'),
    invitations: {
      root: path(ROOTS_MANAGE, '/users/invitations'),
      invitation: (invitationId: string) => path(ROOTS_MANAGE, `/users/invitations/${invitationId}`),
    },
    organization: path(ROOTS_MANAGE, '/users/organization'),
  }
};

export const PATHS_TRACK = {
  root: ROOTS_TRACK,
  trainingPlans: path(ROOTS_TRACK, '/training-plans'),
  trainingPlansByCohort: (planId: string, cohortId: string) => {
    return path(ROOTS_TRACK, `/training-plans/${planId}/${cohortId}`)
  },
  modules: path(ROOTS_TRACK, '/modules'),
  fileManager: path(ROOTS_TRACK, '/file-manager'),
  assessmentManager: path(ROOTS_TRACK, '/assessment-manager'),
}

export const PATHS_PREVIEW = {
  root: ROOTS_PREVIEW,
  plans: {
    root: path(ROOTS_PREVIEW, '/training-plans'),
    plan: (planId: string) => path(ROOTS_PREVIEW, `/training-plans/${planId}`),
    planSection: (planId: string, planSectionId: string) => path(ROOTS_PREVIEW, `/training-plans/${planId}?sectionId=${encodeURIComponent(planSectionId)}`),
    planSectionModule: (planId: string, planSectionId: string, moduleId: string) => path(ROOTS_PREVIEW, `/training-plans/${planId}?sectionId=${encodeURIComponent(planSectionId)}&moduleId=${encodeURIComponent(moduleId)}`),
  },
  modules: {
    root: path(ROOTS_PREVIEW, '/module'),
    module: (id: string, substepId: string, initialStep?: string, experienceType?: string, experienceId?: string) => {
      let basePath = path(ROOTS_PREVIEW, `/module/${id}/${substepId}`);
      const pathParams = [];
      if (initialStep) {
        pathParams.push(`initialStep=${encodeURIComponent(initialStep)}`)
      }
      if (experienceType) {
        pathParams.push(`experience=${encodeURIComponent(experienceType)}`);
      }
      if (experienceId) {
        pathParams.push(`id=${encodeURIComponent(experienceId)}`);
      }
      if (pathParams.length !== 0) {
        basePath = `${basePath}?${pathParams.join('&')}`
      }
      return basePath;
    },
  }
}

export const PATHS_PLAN = {
  root: ROOTS_PLAN,
  manage: path(ROOTS_PLAN, '/manage'),
  builder: path(ROOTS_PLAN, '/manage/builder/'),
  edit: (name: string) => path(ROOTS_PLAN, `/manage/builder/${name}`),
};

export const PATHS_BUILD = {
  root: ROOTS_BUILD,
  list: path(ROOTS_BUILD, '/list'),
  editRoot: path(ROOTS_BUILD, '/edit'),
  edit: (moduleId: string) => {
    return path(ROOTS_BUILD, `/edit/${moduleId}`);
  },
  editTemplateRoot: path(ROOTS_BUILD, '/edit-template/'),
  editTemplate: (moduleId: string) => {
    return path(ROOTS_BUILD, `/edit-template/${moduleId}`);
  },
  progress: path(ROOTS_BUILD, '/progress'),
  modules: path(ROOTS_BUILD, '/modules'),
  newModule: path(ROOTS_BUILD, '/modules/create/legacy/dynamic'),
  templates: path(ROOTS_BUILD, '/templates'),
  preview: path(ROOTS_BUILD, '/preview'),
  organization: path(ROOTS_BUILD, '/organization'),
};


export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
};

export const PATHS_SOLVER = {
  roots: ROOTS_SOLVER,
  dashboard: path(ROOTS_SOLVER, '/dashboard'),
  profile: path(ROOTS_SOLVER, '/profile'),
  help: path(ROOTS_SOLVER, '/help'),
  explore: path(ROOTS_SOLVER, '/explore'),
  exploreCategory: (category: string) => {
    console.log(category);
    if (category && category !== '') {
      return path(ROOTS_SOLVER, `/explore?category=${encodeURIComponent(category)}`)
    }
    return PATHS_SOLVER.explore;
  },
  continue: path(ROOTS_SOLVER, '/continue'),
  cohort: path(ROOTS_SOLVER, '/cohort'),
  plans: {
    root: path(ROOTS_SOLVER, '/plans'),
    plan: (planId: string) => path(ROOTS_SOLVER, `/plans/${planId}`),
    planSection: (planId: string, planSectionId: string) => path(ROOTS_SOLVER, `/plans/${planId}?sectionId=${encodeURIComponent(planSectionId)}`),
    planSectionModule: (planId: string, planSectionId: string, moduleId: string) => path(ROOTS_SOLVER, `/plans/${planId}?sectionId=${encodeURIComponent(planSectionId)}&moduleId=${encodeURIComponent(moduleId)}`),
  }
};
