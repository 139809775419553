import { createContext, useReducer } from "react";
import useLogger from "utils/useReducerLogger";
import actions from "./actions";
import reducers from "./reducers";
import { ActionsType, PLRecordContextType, PLRecordStateType } from "./types";
import utils from './utils';
import { UserRecord, UserRecordRequest, UserRecordAnnotation } from "@piccadilly-cloud/platform-services";

export const PLRecordContext = createContext<PLRecordContextType | null>(null);

const initialState: PLRecordStateType = {
  initialized: false,
  rawRecords: [],
};

const rootReducer = (state: PLRecordStateType, action: ActionsType): PLRecordStateType => {
  if (reducers[action.type]) {
    return reducers[action.type](state, action);
  }
  return state;
}

type PLRecordProps = {
  children: React.ReactNode;
}

export function PLRecordProvider({ children, }: PLRecordProps) {
  const [state, dispatch] = useReducer(
    // eslint-disable-next-line react-hooks/rules-of-hooks
    process.env.REACT_APP_DEBUG_REDUX === 'true' ? useLogger(rootReducer) : rootReducer, initialState
  );

  return (
    <PLRecordContext.Provider
      value={{
        ...state,
        reset: () => actions.reset()(dispatch),
        utils:{
          getCompletePlanSections: (accountId: string, planId: string) => utils.getCompletePlanSections(accountId, planId)(state),
          getCompletedModuleSteps: (accountId: string, moduleId: string, planId?: string, planSectionId?: string) => utils.getCompletedModuleSteps(accountId, moduleId, planId, planSectionId)(state),
          getCompletedModules: (accountId: string, moduleId: string, planId?: string, planSectionId?: string) => utils.getCompletedModules(accountId, moduleId, planId, planSectionId)(state),
          moduleFirstStartedAt: (accountId: string, moduleId: string, planId?: string, planSectionId?: string) => utils.moduleFirstStartedAt(accountId, moduleId, planId, planSectionId)(state),

          getCompletePlanSectionsByCohort: (cohortId: string, planId: string) => utils.getCompletePlanSectionsByCohort(cohortId, planId)(state),
          getCompletedModuleStepsByCohort: (cohortId: string, moduleId: string, planId?: string, planSectionId?: string) => utils.getCompletedModuleStepsByCohort(cohortId, moduleId, planId, planSectionId)(state),
          getCompletedModulesByCohort: (cohortId: string, moduleId: string, planId?: string, planSectionId?: string) => utils.getCompletedModulesByCohort(cohortId, moduleId, planId, planSectionId)(state),
          moduleFirstStartedAtByCohort: (cohortId: string, moduleId: string, planId?: string, planSectionId?: string) => utils.moduleFirstStartedAtByCohort(cohortId, moduleId, planId, planSectionId)(state),
        },
        loadRecords: (nextRecords: UserRecord[], merge?: boolean) => actions.loadRecords(nextRecords, merge)(dispatch),
        createRecord: (token: string, recordRequest: UserRecordRequest) => actions.createRecord(token, recordRequest)(dispatch),
        createPreviewRecord: (recordRequest: UserRecordRequest) => actions.createPreviewRecord(recordRequest)(dispatch),
        invalidateRecord: (token: string, appId: string, recordId: string) => actions.invalidateRecord(token, appId, recordId)(dispatch),
        invalidateRecordByAdmin: (token: string, appId: string, recordId: string) => actions.invalidateRecordByAdmin(token, appId, recordId)(dispatch),
        invalidateAllRecordsByAdmin: (token: string, appId: string, recordIds: string[]) => actions.invalidateAllRecordsByAdmin(token, appId, recordIds)(dispatch),
        updateAnnotation: (token: string, appId: string, recordId: string, annotation: UserRecordAnnotation) => actions.updateAnnotation(token, appId, recordId, annotation)(dispatch),
      }}
    >
      {children}
    </PLRecordContext.Provider>
  )
}