import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link, BoxProps } from '@mui/material';
import { PATHS_MANAGE } from 'routes/paths';
import { useSessionContext } from 'contexts/session/useSessionContext';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  height?: number;
  secondary?: boolean;
  disabledStyle?: boolean;
}

const LogoApp = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, height, sx, ...other }, ref) => {
    const theme = useTheme();
    const session = useSessionContext();

    const isLight = theme.palette.mode === 'light';

    let logoSource = session.appConfig.brandingSettings.primaryLogoUrl;

    if (!isLight) {
      logoSource = session.appConfig.brandingSettings.secondaryLogoUrl;
    }

    let inlineStyle: any = { height: height ? height : 40, cursor: 'pointer', ...sx };
    if (other.disabledStyle) {
      inlineStyle = {};
    }

    const logo = (
      <Box
        component="img"
        src={logoSource}
        sx={inlineStyle}
      />
    );

    if (disabledLink) {
      return <>{logo}</>;
    }

    if (session.account.accountRole === 'GUEST') {
      return (
        <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
          {logo}
        </Link>  
      )
    }

    return (
      <Link to={PATHS_MANAGE.dashboard} component={RouterLink} sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default LogoApp;
