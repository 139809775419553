import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Box, Divider, MenuItem, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
// routes
import { PATHS_USER, PATH_AUTH, } from '../../../routes/paths';
// auth
import { useSessionContext } from '../../../contexts/session/useSessionContext';
// components
import ctrl from '@piccadilly-libs-backend/ctrl';
import { usePLRecordContext } from 'contexts/plrecord/usePLRecordContext';
import sessionUtil from 'utils/data/sessionUtil';
import { IconButtonAnimate } from '../../../components/animate';
import { CustomAvatar } from '../../../components/custom-avatar';
import MenuPopover from '../../../components/menu-popover';
import { useSnackbar } from '../../../components/snackbar';
import PolicyAgreementDialog from 'components/policy-agreement/PolicyAgreementDialog';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const session = useSessionContext();
  const navigate = useNavigate();
  const permissions = ctrl.permissions.getForUser(session);
  const [openPolicyDialog, setOpenPolicyDialog] = useState(false);

  const OPTIONS = [
    {
      label: 'Home',
      linkTo: '/',
    },
    ...(permissions.as_admin.nav.my_profile_visible ? [{
      label: 'Profile',
      linkTo: PATHS_USER.profile,
    }] : []),
  ];

  const { account, logout } = useSessionContext();
  const { reset, } = usePLRecordContext();

  const { enqueueSnackbar } = useSnackbar();

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    try {
      logout();
      reset();
      navigate(PATH_AUTH.login, { replace: true });
      handleClosePopover();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleClickItem = (path: string) => {
    handleClosePopover();
    navigate(path);
  };

  const initials = (s: string): string => {
    return s.split(' ').map((v) => {
      if (v.length >= 1) {
        return v[0].toUpperCase()
      }
      return '';
    }).join('');
  }

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <CustomAvatar color='primary' sx={{ fontSize: 'small', }}>
          {initials(account.username || '')}
        </CustomAvatar>
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 300, p: 0 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {account.username}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {account.email}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {sessionUtil.getUsersRole(account.accountRole)}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        {session.appConfig.securitySettings.usePolicyAgreement && (
          <>
            <Divider sx={{ borderStyle: 'dashed' }} />

            <MenuItem onClick={() => setOpenPolicyDialog(true)} sx={{ m: 1 }}>
              Terms of Service
            </MenuItem>
          </>
        )}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>

      {session.appConfig.securitySettings.usePolicyAgreement && session.appConfig.securitySettings.policyAgreement && (
        <PolicyAgreementDialog
          open={openPolicyDialog}
          onClose={() => setOpenPolicyDialog(false)}
        />
      )}
    </>
  );
}
