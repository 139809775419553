import React from "react";
import { ActionsType, CacheStateType, CacheTypes } from "../types";
import services from "@piccadilly-cloud/platform-services";

const trainingPlanCacheReducer = (state: CacheStateType, action: ActionsType) => {
  if (action.type === CacheTypes.TRAINING_PLAN_CACHE_REFRESH) {
    state.trainingPlanCache = [...action.payload.trainingPlans];
  }
  return { ...state, };
}

const trainingPlans = (token: string, enabledApp: string, cohortId?: string) => {
  return (dispatch: React.Dispatch<any>) => {
    if (cohortId) {
      services.edge.content.trainingPlans.getForCohort(cohortId)({ token: token, vendor: enabledApp, })
        .then((res) => {
          dispatch({ type: CacheTypes.TRAINING_PLAN_CACHE_REFRESH, payload: { trainingPlans: res, } })
        }).catch((err) => {
          console.error('could not cache training plans', err);
        });
    } else {
      services.edge.content.trainingPlans.getAll()({ token: token, vendor: enabledApp, })
        .then((res) => {
          dispatch({ type: CacheTypes.TRAINING_PLAN_CACHE_REFRESH, payload: { trainingPlans: res, } })
        }).catch((err) => {
          console.error('could not cache training plans', err);
        });
    }
  };
}

const trainingPlanCache = {
  reducers: {
    [CacheTypes.TRAINING_PLAN_CACHE_REFRESH]: trainingPlanCacheReducer,
  },
  methods: {
    trainingPlans,
  },
};

export default trainingPlanCache;
