import { useRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Button, AppBar, Toolbar, Container, BoxProps } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
// utils
import { bgBlur } from '../../utils/cssStyles';
// config
import { HEADER } from '../../config';
// routes
import { PATH_AUTH, } from '../../routes/paths';
// components
import Logo from '../../components/logo';
//
import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
import { useNavigate } from 'react-router-dom';
import getNavConfig from './nav/config';
import { useSessionContext } from 'contexts/session/useSessionContext';

// ----------------------------------------------------------------------

export default function Header() {
  const session = useSessionContext();
  const carouselRef = useRef(null);
  const navigate = useNavigate();

  const theme = useTheme();

  const isDesktop = useResponsive('up', 'md');

  let isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  if (session.appConfig.landingSettings.useLandingOnly) {
    isOffset = true;
  }

  return (
    <>
      <AppBar ref={carouselRef} color="transparent" sx={{ boxShadow: 0 }}>
        <Toolbar
          disableGutters
          sx={{
            height: {
              xs: HEADER.H_MOBILE,
              md: HEADER.H_MAIN_DESKTOP,
            },
            transition: theme.transitions.create(['height', 'background-color'], {
              easing: theme.transitions.easing.easeInOut,
              duration: theme.transitions.duration.shorter,
            }),
            ...(isOffset && {
              ...bgBlur({ color: theme.palette.background.default }),
              height: {
                md: HEADER.H_MAIN_DESKTOP - 16,
              },
            }),
          }}
        >
          <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Logo />

            <Box sx={{ flexGrow: 1 }} />

            {isDesktop && <NavDesktop isOffset={isOffset} data={getNavConfig(session.appConfig.landingSettings.useLandingOnly)} />}

            <Button color="primary" variant="contained" onClick={() => { navigate(PATH_AUTH.login) }}>
              Login
            </Button>

            {!isDesktop && <NavMobile isOffset={isOffset} data={getNavConfig(session.appConfig.landingSettings.useLandingOnly)} />}
          </Container>
        </Toolbar>

        {isOffset && <Shadow />}
      </AppBar>
    </>
  );
}

// ----------------------------------------------------------------------

function Shadow({ sx, ...other }: BoxProps) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
