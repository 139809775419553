import { PublicAccount, Cohort } from "@piccadilly-cloud/platform-services";
import { SessionContextType } from "contexts/session/types";
import { fDate } from "utils/formatTime";

const getUsername = (account: PublicAccount): string => {
  return account.username;
}

const getCreatedAt = (account: PublicAccount): string => {
  return fDate(account.createdAt, 'MMM dd, yyyy')
}

const getAccountRole = (account: PublicAccount): string => {
  const role = account.accountRole;
  return role
    .split('_')
    .map((str) => {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    })
    .join(' ');
}

const getCohortGroupLabel = (session: SessionContextType, account: PublicAccount): string => {
  const group = session.appConfig.cohortGroupSettings.cohortGroups.find((group) => group.referenceId === account.cohortGroupId);
  if (group) {
    return group.label;
  }
  return '';
}

const getCohortLabel = (session: SessionContextType, account: PublicAccount): string => {
  const cohorts: Cohort[] = [];
  session.appConfig.cohortGroupSettings.cohortGroups.forEach((cohortGroup) => {
    cohorts.push(...cohortGroup.cohorts);
  });
  const cohort = cohorts.find((cohort) => cohort.referenceId === account.cohortId);
  if (cohort) {
    return cohort.label;
  }
  return '';

}

const isFacilitator  = (account: PublicAccount): boolean => {
  const role = account.accountRole;
  return role !== 'GUEST' && role !== 'SOLVER';
}

const gradeLevelOpts = (): { option: string, label: string, }[] => {
  const opts = [
    { label: '5', option: '5' },
    { label: '6', option: '6' },
    { label: '7', option: '7' },
    { label: '8', option: '8' },
    { label: '9', option: '9' },
    { label: '10', option: '10' },
    { label: '11', option: '11' },
    { label: '12', option: '12' },
    { label: 'College', option: 'COLLEGE' },
    { label: 'N/A', option : 'NA'}
  ];
  return opts
}

const ageOpts = (): { option: string, label: string, }[] => {
  const opts = [
    { label: '10', option: '10' },
    { label: '11', option: '11' },
    { label: '12', option: '12' },
    { label: '13', option: '13' },
    { label: '14', option: '14' },
    { label: '15', option: '15' },
    { label: '16', option: '16' },
    { label: '17', option: '17' },
    { label: '18', option: '18' },
    { label: '19', option: '19' },
    { label: 'Other', option: 'OTHER' },
  ];
  return opts;
}

const genderOpts = (): { option: string, label: string, }[] => {
  const opts = [
    {label: 'Woman', option: 'woman', },
    {label: 'Man', option: 'man', },
    {label: 'Other/Prefer not to say', option: 'other', },
  ];
  return opts;
}

const raceOpts = (): { option: string, label: string, }[] => {
  const opts = [
    { label: 'Asian', option: 'Asian' },
    { label: 'Black/African', option: 'Black/African' },
    { label: 'White', option: 'White' },
    { label: 'Hispanic/Latino', option: 'Hispanic/Latino' },
    { label: 'Pacific Islander', option: 'Pacific Islander' },
    { label: 'Two or more races', option: 'Two or more races' },
    { label: 'Other/Prefer not to say', option: 'Other/Prefer not to say' },
  ]
  return opts;
}

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const normalizePhoneNumber = (number: string): string => {
  const newNumber = number
    .replaceAll(' ', '')
    .replaceAll('(', '')
    .replaceAll(')', '')
    .replaceAll('-', '')
    .replaceAll('_', '');
  return `+1${newNumber}`;
}


const userAccount = {
  gradeLevelOpts,
  ageOpts,
  genderOpts,
  raceOpts,
  getUsername,
  getCreatedAt,
  getAccountRole,
  getCohortGroupLabel,
  getCohortLabel,
  isFacilitator,
  phoneRegExp,
  emailRegex,
  normalizePhoneNumber,
};

export default userAccount;
