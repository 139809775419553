import React from "react";
import { ActionsType, CacheStateType, CacheTypes } from "../types";
import services from "@piccadilly-cloud/platform-services";

const moduleOverviewCacheReducer = (state: CacheStateType, action: ActionsType) => {
  if (action.type === CacheTypes.MODULE_OVERVIEW_CACHE_REFRESH) {
    state.moduleOverviewsCache = [...action.payload.moduleOverviews];
  }
  return { ...state, };
}

const moduleTemplateOverviewCacheReducer = (state: CacheStateType, action: ActionsType) => {
  if (action.type === CacheTypes.MODULE_TEMPLATE_OVERVIEW_CACHE_REFRESH) {
    state.moduleTemplateOverviewsCache = [...action.payload.moduleOverviews];
  }
  return { ...state, };
}

const moduleCacheReducer = (state: CacheStateType, action: ActionsType) => {
  if (action.type === CacheTypes.MODULE_CACHE_REFRESH) {
    if (action.payload.module) {
      state.modules.cache[action.payload.module.overview.referenceId] = action.payload.module;
    } else {
      state.modules.notFound = [...state.modules.notFound, action.payload.moduleId];
    }
  }
  return { ...state, };
}

const cacheModuleOverviews = (token: string, enabledApp: string) => {
  return (dispatch: React.Dispatch<any>) => {
    services.edge.content.modules.getOverview()({ token, vendor: enabledApp, })
      .then((res) => {
        dispatch({ type: CacheTypes.MODULE_OVERVIEW_CACHE_REFRESH, payload: { moduleOverviews: res, }})
      }).catch((err) => {
        console.error('could not cache module overviews', err);
      });
  };
}

const cacheModuleTemplateOverviews = (token: string, vendor: string) => {
  return (dispatch: React.Dispatch<any>) => {
    services.edge.content.moduleTemplates.getAllPreviewsForVendor()({ token, vendor, })
      .then((res) => {
        dispatch({ type: CacheTypes.MODULE_TEMPLATE_OVERVIEW_CACHE_REFRESH, payload: { moduleOverviews: res, }})
      }).catch((err) => {
        console.error('could not cache module overviews', err);
      });
  };
}

const cacheModule = (currentState: CacheStateType, token: string, enabledApp: string, moduleId: string) => {
  return (dispatch: React.Dispatch<any>) => {
    if (currentState.modules.cache[moduleId] || currentState.modules.notFound.includes(moduleId)) {
      console.log('item already exists');
    } else {
      services.edge.content.modules.getById(moduleId)({ token, vendor: enabledApp, })
        .then((res) => {
          dispatch({ type: CacheTypes.MODULE_CACHE_REFRESH, payload: { module: res, moduleId, }})
        }).catch((err) => {
          dispatch({ type: CacheTypes.MODULE_CACHE_REFRESH, payload: { moduleId, }})
          console.error('could not cache module overviews', err);
        });
    }
  }
}

const moduleCache = {
  reducers: {
    [CacheTypes.MODULE_TEMPLATE_OVERVIEW_CACHE_REFRESH]: moduleTemplateOverviewCacheReducer,
    [CacheTypes.MODULE_OVERVIEW_CACHE_REFRESH]: moduleOverviewCacheReducer,
    [CacheTypes.MODULE_CACHE_REFRESH]: moduleCacheReducer,
  },
  methods: {
    cacheModuleOverviews,
    cacheModuleTemplateOverviews,
    cacheModule,
  },
};

export default moduleCache;
