import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link, BoxProps } from '@mui/material';
import { useSettingsContext } from '../settings';
import { PATHS_MANAGE } from 'routes/paths';
import { useSessionContext } from 'contexts/session/useSessionContext';
import sessionUtil from 'utils/data/sessionUtil';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  height?: number;
  secondary?: boolean;
  disabledStyle?: boolean;
  logoSource?: string;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, height, sx, logoSource, ...other }, ref) => {
    const theme = useTheme();
    const session = useSessionContext();
    const { themeLayout } = useSettingsContext();

    const isLight = theme.palette.mode === 'light';

    const isNavMini = themeLayout === 'mini';


    if (!logoSource) {
      logoSource = '/volker/logo/piccadilly_learn_tulips_rev1.2.png';
  
      if (!isLight) {
        logoSource = '/volker/logo/piccadilly-learn-light.png';
      }
  
      if (isNavMini) {
        logoSource = '/volker/logo/tulip-main.png';
      }
  
      if (other.secondary) {
        logoSource = '/volker/logo/piccadilly-learn-light.png'
      }
  
      if (sessionUtil.getDefaultSessionType() === 'NONPROFIT') {
        logoSource = '/volker/accelerus-branding/accelerus-rev-2.png';
      }
  
      if (session.appConfig.landingSettings.useLandingOnly) {
        logoSource = session.appConfig.brandingSettings.primaryLogoUrl;
        // if (session.appConfig.landingSettings.landingLogoUrl && session.appConfig.landingSettings.landingLogoUrl !== '') {
        //   logoSource = session.appConfig.landingSettings.landingLogoUrl;
        // } else {
        // }
      }
    }

    let inlineStyle: any = { height: height ? height : 40, cursor: 'pointer', ...sx };
    if (other.disabledStyle) {
      inlineStyle = {};
    }

    const logo = (
      <Box
        component="img"
        src={logoSource}
        sx={inlineStyle}
      />
    );

    if (disabledLink) {
      return <>{logo}</>;
    }

    if (session.account.accountRole === 'GUEST') {
      return (
        <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
          {logo}
        </Link>  
      )
    }

    return (
      <Link to={PATHS_MANAGE.dashboard} component={RouterLink} sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
