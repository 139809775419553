import services, { Session, UserRecord } from '@piccadilly-cloud/platform-services';

const isPendingReview = (record?: UserRecord): boolean => {
  if (!record) {
    return false;
  }
  const doesRequireReview = !!record.annotation?.review?.requiresReview;
  const hasBeenReviewed = !!record?.annotation?.review?.reviewed;
  return doesRequireReview && !hasBeenReviewed;
}

const isRejectedReview = (record?: UserRecord): boolean => {
  if (!record) {
    return false;
  }
  const doesRequireReview = !!record.annotation?.review?.requiresReview;
  const hasBeenReviewed = !!record.annotation?.review?.reviewed;
  const wasRejected = !!record.annotation?.review?.rejected
  return doesRequireReview && hasBeenReviewed && wasRejected;
}

const isApprovedReview = (record?: UserRecord): boolean => {
  if (!record) {
    return false;
  }
  const doesRequireReview = !!record.annotation?.review?.requiresReview;
  const hasBeenReviewed = !!record.annotation?.review?.reviewed;
  const wasRejected = !!record.annotation?.review?.rejected
  return doesRequireReview && hasBeenReviewed && !wasRejected;
}

const isRequiredReview = (record?: UserRecord): boolean => {
  if (!record) {
    return false;
  }
  const doesRequireReview = !!record.annotation?.review?.requiresReview;
  return doesRequireReview;
}

const isReviewed = (record?: UserRecord): boolean => {
  if (!record) {
    return false;
  }

  const hasBeenReviewed = !!record.annotation?.review?.reviewed;
  const wasScored = !!record.annotation?.review?.reviewedScore;
  return hasBeenReviewed && wasScored;
}

const getAssessmentSubmissionRecords = (records: UserRecord[]): UserRecord[] => {
  return records
    .filter((r) => r.data.scope === 'QUIZ_SUBMISSION')
    .filter((r) => r.data.action === 'COMPLETED')
    .filter((r) => r.keys.accountRole !== 'FACILITATOR')
    .filter((r) => r.keys.accountRole !== 'ACCOUNT_HOLDER')
    .filter((r) => r.keys.accountRole !== 'SINGLE_GROUP_FACILITATOR')
    .filter((r) => r.keys.accountRole !== 'SINGLE_GROUP_SME')
    .filter((r) => r.keys.accountRole !== 'SME')
    .filter((r) => r.keys.accountRole !== 'GUEST');
}

const exportAssessmentSubmissions = (session: Session, recordIds: string[]) => {
  try {
    return services.edge.records.user.exportQuizSubmissions(recordIds)({ token: session.token, vendor: session.appConfig.vendor, })
  } catch (err) {
    console.log('export error!', err)
  }
}

const assessmentManagerUtils = {
  isPendingReview,
  isRejectedReview,
  isApprovedReview,
  isRequiredReview,
  isReviewed,
  getAssessmentSubmissionRecords,
  exportAssessmentSubmissions
};

export default assessmentManagerUtils;