import appConfig from "./appConfig";
import fileManager from "./fileManager";
import permissions from "./permissions";
import session from "./session";
import userAccount from "./userAccount";
import userRecord from "./userRecord";
import assessmentManager from "./assessmentManager";

const ctrl = {
  session,
  appConfig,
  userAccount,
  userRecord,
  permissions,
  fileManager,
  assessmentManager,
};

export default ctrl;