import { PlatformPermissions } from "@piccadilly-cloud/platform-services";
import { SessionContextType } from "contexts/session/types";

const getForUser = (session: SessionContextType): PlatformPermissions => {
  return session.platformPermissions;
}

const userCanEditOwnCohortGroup = (session: SessionContextType): boolean => {
  return session.platformPermissions.as_user.can_edit_own_cohort_group;
}

const userCanEditOwnCohort = (session: SessionContextType): boolean => {
  return session.platformPermissions.as_user.can_edit_own_cohort;
}

const userCanEditOwnAccountRole = (session: SessionContextType): boolean => {
  return session.platformPermissions.as_user.can_edit_own_role;
}

const permissions = {
  getForUser,

  userCanEditOwnCohortGroup,
  userCanEditOwnCohort,
  userCanEditOwnAccountRole,
};

export default permissions;
