import React from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  useTheme,
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { bgBlur } from 'utils/cssStyles';
import NavList from './FooterItem';
import Iconify from 'components/iconify';
import { PATHS_MANAGE, PATHS_SOLVER, } from 'routes/paths';
import { useSessionContext } from 'contexts/session/useSessionContext';
import sessionUtil from 'utils/data/sessionUtil';

export default function Control(props: {
  onOpenNav: VoidFunction,
}) {
  const session = useSessionContext();
  const theme = useTheme();


  let actions = [
    {
      title: 'Dashboard',
      icon: <><Iconify icon="ph:dna" /></>,
      path: PATHS_SOLVER.dashboard,
      // onClick: () => {}, 
    },
    {
      title: sessionUtil.getTrainingPlanLabel(true)(session),
      icon: <><Iconify icon="ph:rocket-launch" /></>,
      path: PATHS_SOLVER.plans.root,
      // onClick: () => {},
    },
    {
      title: `Explore ${sessionUtil.getModuleLabel(true)(session)}`,
      icon: <><Iconify icon="ph:paper-plane-tilt" /></>,
      path: PATHS_SOLVER.explore,
      // onClick: () => {},
    },
    {
      title: 'Menu',
      icon: <><Iconify icon="ph:list" /></>,
      path: '#',
      onClick: () => { props.onOpenNav() }, 
    },
  ];

  if (session.account.accountRole !== 'SOLVER') {
    actions = [{

      title: 'Facilitator Dashboard',
      icon: <><Iconify icon="eos-icons:admin-outlined" /></>,
      path: PATHS_MANAGE.dashboard,

    }, ...actions];
  }

  return (
    <>
      <AppBar
        color='transparent'
        sx={{ top: 'auto', bottom: 0 }}
        // sx={{
        //   bottom: 0,
        //   height: '4rem',
        //   top: 'calc(100vh - 9rem)'
        // }}
      >
        <Toolbar
          sx={{
            ...bgBlur({
              color: theme.palette.background.default,
              opacity: 0.9,
            }),
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-evenly"
            alignItems="center"
            sx={{
              mt: '0.25rem',
              width: '100%',
              pb: 0,
              mb: isMobile ? '1rem' : 0,
            }}
          >
            {actions.map((a) => (
              <NavList
                key={`action-${a.title}`}
                depth={1}
                hasChild={false}
                data={a}
              />
            ))}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  )
}