import React from 'react';

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/third_party/embedly.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/video.min.js";
import "froala-editor/js/plugins/font_family.min.js";
import "froala-editor/js/plugins/font_size.min.js";
// import "froala-editor/js/plugins/fullscreen.min.js"

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/third_party/embedly.min.css";
import "froala-editor/css/plugins/video.min.css";
import "froala-editor/css/plugins/image.min.css";
// import "froala-editor/css/plugins/fullscreen.min.css";

import Froala from "react-froala-wysiwyg";
import { FroalaUploadRequest } from '@piccadilly-cloud/platform-services';
// import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
// import Tribute from "tributejs";
// import "tributejs/dist/tribute.css";

export default function FroalaEditor(props: {
  editorKey: string,
  defaultHtmlValue: string,
  onChange: (nextHtmlValue: string) => void,
  assetManagerImageOpts: () => FroalaUploadRequest,
  defaultText?: string,
  canvasEditor?: boolean,
  heightMax?: number,
}) {

  const [isFroalaInitialized, setIsFroalaInitialized] = React.useState(false);
  const [model, setModel] = React.useState<string>("");
  const [currentEditorKey, setCurrentEditorKey] = React.useState('');

  const defaultValueCB = React.useCallback(() => {
    if (!isFroalaInitialized && props.editorKey !== '') {
      setIsFroalaInitialized(true);
      setCurrentEditorKey(props.editorKey);
      setModel(props.defaultHtmlValue);
    } 
    if (props.editorKey !== currentEditorKey) {
      setCurrentEditorKey(props.editorKey);
      setModel(props.defaultHtmlValue);
    }
  }, [currentEditorKey, isFroalaInitialized, props.defaultHtmlValue, props.editorKey]);
  React.useEffect(defaultValueCB, [defaultValueCB, props.editorKey]);

  const handleModelChange = (model: any, others: any) => {
    if (props.editorKey === currentEditorKey) {
      props.onChange(model);
      setModel(model);
    }
  };

  return (
    <Froala
      model={model}
      onModelChange={handleModelChange}
      config={{
        key: process.env.REACT_APP_F_LICENSE,
        attribution: false,
        placeholder: "Start typing...",
        heightMin: props.canvasEditor ? '20rem' : '12rem',
        heightMax: props.heightMax || 'auto',
        zIndex: 9990,
        placeholderText: props.defaultText ? props.defaultText : 'Start typing...',
        videoUploadParam: 'file',
        videoUploadURL: `${process.env.REACT_APP_EDGE_URL}/upload/froala`,
        videoUploadParams: {request: JSON.stringify(props.assetManagerImageOpts()), },
        videoUploadMethod: 'POST',
        videoMaxSize: 50 * 1024 * 1024,
        videoAllowedTypes: ['webm', 'jpg', 'ogg', 'mov', 'MOV', 'mp4', 'quicktime'],
        videoAllowedProviders: ['youtube', 'vimeo'],

        imageUploadURL: `${process.env.REACT_APP_EDGE_URL}/upload/froala`,
        imageUploadParam: 'file',
        imageUploadMethod: 'POST',
        imageUploadParams: { request: JSON.stringify(props.assetManagerImageOpts()), },
        htmlExecuteScripts: false,
        events: {
          'image.beforeUpload': (img: any) => {
            console.log('what do we have here', img)
          },
          'image.uploaded': (resp: any) => {
            console.log('the resp', resp)
          },
          'image.error': (err: any, resp: any) => {
            console.log('image.err', err, resp)
          }
        },
        fontFamily: {
          'Open Sans,Helvetica,sans-serif': 'Open Sans',
          // 'Impact,Charcoal,sans-serif': 'Font 2',
          // 'Tahoma,Geneva,sans-serif': 'Font 3'
        },
        fontFamilySelection: true,
        fontFamilyDefaultSelection: 'Open Sans',
        toolbarButtons: {
          moreText: {
            buttons: [
              "bold",
              "italic",
              "underline",
              "fontSize",
              "strikeThrough",
              "subscript",
              "superscript",
              "fontFamily",
              "textColor",
              "backgroundColor",
              "inlineClass",
              "inlineStyle",
              "clearFormatting"
            ]
          },
          moreParagraph: {
            buttons: [
              "alignLeft",
              "alignCenter",
              "formatOLSimple",
              "alignRight",
              "alignJustify",
              "formatOL",
              "formatUL",
              "paragraphFormat",
              "paragraphStyle",
              "lineHeight",
              "outdent",
              "indent",
              "quote"
            ]
          },
          moreRich: {
            buttons: [
              "insertLink",
              "insertImage",
              "insertVideo",
              "insertTable",
              "emoticons",
              "fontAwesome",
              "specialCharacters",
              "embedly",
              // "insertFile",
              "insertHR"
            ]
          },
          moreMisc: {
            buttons: [
              "undo",
              "redo",
              "fullscreen",
              "print",
              "spellChecker",
              "selectAll",
              "html",
              "help"
            ],
            align: "right",
            buttonsVisible: 2
          }
        },
        pluginsEnabled: [
          "table",
          "spell",
          "quote",
          "save",
          "paragraphFormat",
          "paragraphStyle",
          "help",
          "draggable",
          "fontFamily",
          "fontSize",
          "align",
          "link",
          "lists",
          "file",
          "image",
          "emoticons",
          "url",
          "video",
          "embedly",
          "colors",
          "entities",
          "inlineClass",
          "inlineStyle",
          'spellChecker',
          "imageTUI",
          "fullscreen"
        ]
      }}
    />
  )
}