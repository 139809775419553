import { UserRecord } from "@piccadilly-cloud/platform-services";
import { PLRecordStateType } from "./types";

const getCompletePlanSections = (accountId: string, planId: string): (state: PLRecordStateType) => UserRecord[] => {
  return (state: PLRecordStateType): UserRecord[] => {
    let stage = state.rawRecords
      .filter((r) => r.data.action === 'COMPLETED')
      .filter((r) => r.data.scope === 'PLAN_SECTION')
      .filter((r) => r.keys.accountId === accountId)
      .filter((r) => r.data.planId === planId);

    return stage;
  }
}

const getCompletePlanSectionsByCohort = (cohortId: string, planId: string): (state: PLRecordStateType) => UserRecord[] => {
  return (state: PLRecordStateType): UserRecord[] => {
    let stage = state.rawRecords
      .filter((r) => r.data.action === 'COMPLETED')
      .filter((r) => r.data.scope === 'PLAN_SECTION')
      .filter((r) => r.keys.cohortId === cohortId)
      .filter((r) => r.data.planId === planId);

    return stage;
  }
}

const getCompletedModuleSteps = (accountId: string, moduleId: string, planId?: string, planSectionId?: string): (state: PLRecordStateType) => UserRecord[] => {
  return (state: PLRecordStateType): UserRecord[] => {
    let stage = state.rawRecords
      .filter((r) => r.data.action === 'COMPLETED')
      .filter((r) => r.data.scope === 'MODULE_STEP')

      .filter((r) => r.keys.accountId === accountId)
      .filter((r) => r.data.moduleId === moduleId);

    if (planId) {
      stage = stage.filter((r) => r.data.planId === planId)
    }
    if (planSectionId) {
      stage = stage.filter((r) => r.data.planSectionId === planSectionId)
    }
    return stage;
  }
}

const getCompletedModuleStepsByCohort = (cohortId: string, moduleId: string, planId?: string, planSectionId?: string): (state: PLRecordStateType) => UserRecord[] => {
  return (state: PLRecordStateType): UserRecord[] => {
    let stage = state.rawRecords
      .filter((r) => r.data.action === 'COMPLETED')
      .filter((r) => r.data.scope === 'MODULE_STEP')

      .filter((r) => r.keys.cohortId === cohortId)
      .filter((r) => r.data.moduleId === moduleId);

    if (planId) {
      stage = stage.filter((r) => r.data.planId === planId)
    }
    if (planSectionId) {
      stage = stage.filter((r) => r.data.planSectionId === planSectionId)
    }
    return stage;
  }
}

const getCompletedModules = (accountId: string, moduleId: string, planId?: string, planSectionId?: string): (state: PLRecordStateType) => UserRecord[] => {
  return (state: PLRecordStateType): UserRecord[] => {
    let stage = state.rawRecords
      .filter((r) => r.data.action === 'COMPLETED')
      .filter((r) => r.data.scope === 'MODULE')
      .filter((r) => r.keys.accountId === accountId)
      .filter((r) => r.data.moduleId === moduleId);

    if (planId) {
      stage = stage.filter((r) => r.data.planId === planId)
    } else {
      stage = stage.filter((r) => !r.data.planId || r.data.planId === "")
    }
    if (planSectionId) {
      stage = stage.filter((r) => r.data.planSectionId === planSectionId)
    } else {
      stage = stage.filter((r) => !r.data.planSectionId || r.data.planSectionId === "")
    }
    return stage;
  }
}

const getCompletedModulesByCohort = (cohortId: string, moduleId: string, planId?: string, planSectionId?: string): (state: PLRecordStateType) => UserRecord[] => {
  return (state: PLRecordStateType): UserRecord[] => {
    let stage = state.rawRecords
      .filter((r) => r.data.action === 'COMPLETED')
      .filter((r) => r.data.scope === 'MODULE')
      .filter((r) => r.keys.cohortId === cohortId)
      .filter((r) => r.data.moduleId === moduleId);

    if (planId) {
      stage = stage.filter((r) => r.data.planId === planId)
    }
    if (planSectionId) {
      stage = stage.filter((r) => r.data.planSectionId === planSectionId)
    }
    return stage;
  }
}

const moduleFirstStartedAt = (accountId: string, moduleId: string, planId?: string, planSectionId?: string): (state: PLRecordStateType) => Date | undefined => {
  return (state: PLRecordStateType): Date | undefined => {
    let stage = state.rawRecords
      .filter((r) => r.keys.accountId === accountId)
      .filter((r) => r.data.moduleId === moduleId);
    if (planId) {
      stage = stage.filter((r) => r.data.planId === planId)
    }
    if (planSectionId) {
      stage = stage.filter((r) => r.data.planSectionId === planSectionId)
    }
    if (stage.length === 0) {
      return undefined
    }
    const dates = stage.flatMap((x) => x.timestamps).sort();
    return dates[0];
  }
}

const moduleFirstStartedAtByCohort = (accountId: string, moduleId: string, planId?: string, planSectionId?: string): (state: PLRecordStateType) => Date | undefined => {
  return (state: PLRecordStateType): Date | undefined => {
    let stage = state.rawRecords
      .filter((r) => r.keys.accountId === accountId)
      .filter((r) => r.data.moduleId === moduleId);
    if (planId) {
      stage = stage.filter((r) => r.data.planId === planId)
    }
    if (planSectionId) {
      stage = stage.filter((r) => r.data.planSectionId === planSectionId)
    }
    if (stage.length === 0) {
      return undefined
    }
    const dates = stage.flatMap((x) => x.timestamps).sort();
    return dates[0];
  }
}

const isPlanSectionComplete = (accountId: string, planId: string, planSectionId: string): (state: PLRecordStateType) => boolean => {
  return (state: PLRecordStateType): boolean => {
    return getCompletePlanSections(accountId, planId)(state)
      .filter((r) => r.data.planSectionId === planSectionId).length >= 1;
  }
}

const arePreviousSectionsComplete = (accountId: string, planId: string, planSectionId: string, sectionIdsInOrder: string[]): (state: PLRecordStateType) => boolean => {
  return (state: PLRecordStateType): boolean => {
    const completedPlanSections = getCompletePlanSections(accountId, planId)(state)
      .map((record) => record.data.planSectionId);

    const targetIndex = sectionIdsInOrder.findIndex((i) => i === planSectionId);
    if (targetIndex === -1) {
      console.error('could not determine if all previous sections were complete')
      return true;
    }

    const stepsToCheck = sectionIdsInOrder.slice(0, targetIndex)
    let allComplete = true;
    for (let stepId of stepsToCheck) {
      if (!completedPlanSections.includes(stepId)) {
        allComplete = false;
      }
    }
    return allComplete;
  }
}

const getPlanCompleteRecord = (accountId: string, planId: string): (state: PLRecordStateType) => UserRecord | undefined => {
  return (state: PLRecordStateType): UserRecord | undefined => {
    const record = state.rawRecords
      .filter((r) => r.data.action === 'COMPLETED')
      .filter((r) => r.data.scope === 'PLAN')
      .filter((r) => r.keys.accountId === accountId)
      .filter((r) => r.data.planId === planId);
    if (record.length === 0) {
      return undefined;
    }
    return record[0];
  }
}

const getDocumentSubmission = (
  accountId: string,
  cohortId: string,
  cohortGroupId: string,
  moduleId?: string,
  module?: string,
  moduleStepId?: string,
  moduleStep?: string,
  planId?: string,
  planSectionId?: string,
  documentSubmissionId?: string,
  documentSubmission?: string,
): (state: PLRecordStateType) => UserRecord[] => {
  return (state: PLRecordStateType): UserRecord[] => {
    const records = state.rawRecords
      .filter((r) => r.data.action === 'COMPLETED')
      .filter((r) => r.data.scope === 'DOCUMENT_SUBMISSION')
      .filter((r) => r.keys.accountId === accountId)
      .filter((r) => r.keys.cohortId === cohortId)
      .filter((r) => r.keys.cohortGroupId === cohortGroupId)
      .filter((r) => r.data.moduleId === moduleId)
      .filter((r) => r.data.module === module)
      .filter((r) => r.data.moduleStepId === moduleStepId)
      .filter((r) => r.data.moduleStep === moduleStep)
      .filter((r) => r.data.planId === planId)
      .filter((r) => r.data.planSectionId === planSectionId)
      .filter((r) => r.data.documentSubmissionId === documentSubmissionId)
      .filter((r) => r.data.documentSubmission === documentSubmission);
    return records;
  }
}

const getAssessmentSubmission = (
  accountId: string,
  cohortId: string,
  cohortGroupId: string,
  moduleId?: string,
  module?: string,
  moduleStepId?: string,
  moduleStep?: string,
  planId?: string,
  planSectionId?: string,
  quizSubmissionId?: string,
  quizSubmission?: string,
): (state: PLRecordStateType) => UserRecord[] => {
  return (state: PLRecordStateType): UserRecord[] => {
    const records = state.rawRecords
      .filter((r) => r.data.action === 'COMPLETED')
      .filter((r) => r.data.scope === 'QUIZ_SUBMISSION')
      .filter((r) => r.keys.accountId === accountId)
      .filter((r) => r.keys.cohortId === cohortId)
      .filter((r) => r.keys.cohortGroupId === cohortGroupId)
      .filter((r) => r.data.moduleId === moduleId)
      .filter((r) => r.data.module === module)
      .filter((r) => r.data.moduleStepId === moduleStepId)
      .filter((r) => r.data.moduleStep === moduleStep)
      .filter((r) => r.data.planId === planId)
      .filter((r) => r.data.planSectionId === planSectionId)
      .filter((r) => r.data.quizSubmissionId === quizSubmissionId)
      .filter((r) => r.data.quizSubmission === quizSubmission);
    return records;
  }
}

const getDocumentSubmissionForPlanForModule = (
  accountId: string,
  cohortId: string,
  cohortGroupId: string,
  moduleId?: string,
  planId?: string,
  planSectionId?: string,
): (state: PLRecordStateType) => UserRecord[] => {
  return (state: PLRecordStateType): UserRecord[] => {
    const records = state.rawRecords
      .filter((r) => r.data.action === 'COMPLETED')
      .filter((r) => r.data.scope === 'DOCUMENT_SUBMISSION')
      .filter((r) => r.keys.accountId === accountId)
      .filter((r) => r.keys.cohortId === cohortId)
      .filter((r) => r.keys.cohortGroupId === cohortGroupId)
      .filter((r) => r.data.moduleId === moduleId)
      .filter((r) => r.data.planId === planId)
      .filter((r) => r.data.planSectionId === planSectionId)
    return records;
  }
}

const plrecordUtils = {
  getCompletePlanSections,
  getCompletedModuleSteps,
  getCompletedModules,
  moduleFirstStartedAt,
  getCompletePlanSectionsByCohort,
  getCompletedModuleStepsByCohort,
  getCompletedModulesByCohort,
  moduleFirstStartedAtByCohort,

  getDocumentSubmission,
  getDocumentSubmissionForPlanForModule,

  getAssessmentSubmission,

  isPlanSectionComplete,
  arePreviousSectionsComplete,
  getPlanCompleteRecord,
}

export default plrecordUtils;
