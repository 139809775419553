// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
// import { FormHelperText } from '@mui/material';
import WYSIWYG from 'components/wysiwyg-froala/WYSIWYG';
import { useSessionContext } from 'contexts/session/useSessionContext';
import { FroalaUploadRequest } from '@piccadilly-cloud/platform-services';
//

// ----------------------------------------------------------------------

interface Props {
  name: string;
  dataKey?: string;
  initialValue?: string;
  onChange?: (nextValue: string) => void;
  assetManagerImageOpts?: () => FroalaUploadRequest;
  defaultText?: string;
  heightMax?: number;
  helperText?: string;
}

export default function RHFEditor({ name, helperText, ...other }: Props) {
  const session = useSessionContext();
  const { control } = useFormContext();

  const getAssetOpts = (): FroalaUploadRequest => {
    return {
      vendor: session.appConfig.vendor,
      userId: session.account.email,
      token: session.token,
    };
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <WYSIWYG
          {...other}
          assetManagerImageOpts={getAssetOpts}
          dataKey={field.name}
          initialValue={field.value}
          onChange={(nextValue: string) => { field.onChange(nextValue); } }
          error={error}
          helperMessage={error ? error?.message : helperText}
        />
      )}
    />
  );
}
