import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSessionContext } from 'contexts/session/useSessionContext';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

export default function PolicyAgreementDialog(props: {
  open: boolean;
  onClose: VoidFunction;
}) {
  const { open, onClose } = props;

  const session = useSessionContext();

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={open}
    >
      <DialogTitle>
        {`${session.appConfig.securitySettings.policyAgreement.title}`}
      </DialogTitle>
      <DialogContent>
        <FroalaEditorView
          config={{
            key: process.env.REACT_APP_F_LICENSE,
          }}
          model={session.appConfig.securitySettings.policyAgreement.body}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

