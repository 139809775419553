import { v4 as uuidv4 } from 'uuid';
import services, { Session, UserRecord, VendorDocument } from "@piccadilly-cloud/platform-services";

const isPendingReview = (record?: UserRecord): boolean => {
  if (!record) {
    return false;
  }
  const doesRequireReview = !!record.annotation?.review?.requiresReview;
  const hasBeenReviewed = !!record?.annotation?.review?.reviewed;
  return doesRequireReview && !hasBeenReviewed;
}

const isRejectedReview = (record?: UserRecord): boolean => {
  if (!record) {
    return false;
  }
  const doesRequireReview = !!record.annotation?.review?.requiresReview;
  const hasBeenReviewed = !!record.annotation?.review?.reviewed;
  const wasRejected = !!record.annotation?.review?.rejected
  return doesRequireReview && hasBeenReviewed && wasRejected;
}

const isApprovedReview = (record?: UserRecord): boolean => {
  if (!record) {
    return false;
  }
  const doesRequireReview = !!record.annotation?.review?.requiresReview;
  const hasBeenReviewed = !!record.annotation?.review?.reviewed;
  const wasRejected = !!record.annotation?.review?.rejected
  return doesRequireReview && hasBeenReviewed && !wasRejected;
}

const isRequireReview = (record?: UserRecord): boolean => {
  if (!record) {
    return false;
  }
  const doesRequireReview = !!record.annotation?.review?.requiresReview;
  return doesRequireReview;
}

const getFileSubmissionRecords = (records: UserRecord[]): UserRecord[] => {
  return records
  .filter((r) => r.data.scope === 'DOCUMENT_SUBMISSION')
  .filter((r) => r.data.action === 'COMPLETED')
  .filter((r) => r.keys.accountRole !== 'FACILITATOR')
  .filter((r) => r.keys.accountRole !== 'ACCOUNT_HOLDER')
  .filter((r) => r.keys.accountRole !== 'SINGLE_GROUP_FACILITATOR')
  .filter((r) => r.keys.accountRole !== 'SINGLE_GROUP_SME')
  .filter((r) => r.keys.accountRole !== 'SME')
  .filter((r) => r.keys.accountRole !== 'GUEST');
}

const downloadFile = (token: string, url: string) => {
  const baseUrl = decodeURIComponent(url.replace(process.env.REACT_APP_DOC_SUB_REPLACE || '', ''));
  services.edge.records.userDocument.getSignedDownloadUrl({ filename: baseUrl, })({ token, })
      .then((signedUrl) => {
        services.edge.records.userDocument.downloadSignedDocument(signedUrl.url)
        .catch((err) => {
          console.log('download error!', err);
        })
    }).catch((err) => {
      console.log('err!', err)
    });
}

const uploadFile = (session: Session, file: File): Promise<VendorDocument> => {
  return services.edge.records.vendorDocument.getSignedUploadUrl({ filename: file.name, contentType: file.type, })({ token: session.token, vendor: session.appConfig.vendor, })
    .then((resp) => {
      return services.edge.records.vendorDocument.uploadSignedDocumentToS3(resp.url, file)
        .then((uploadRes) => {
          const document: VendorDocument = {
            id: uuidv4(),
            vendor: session.appConfig.vendor,
            owner: session.account.email,
            createdAt: new Date(),
            updatedAt: new Date(),
            deleted: false,
            locationUrl: uploadRes.url,
            filename: file.name,
            fileSize: `${file.size}`,
          }
          return services.edge.records.vendorDocument.create(document)({ token: session.token, vendor: session.appConfig.vendor, })
        });
    })
};

const fileManagerUtils = {
  isPendingReview,
  isRejectedReview,
  isApprovedReview,
  isRequireReview,
  getFileSubmissionRecords,
  downloadFile,
  uploadFile,
};

export default fileManagerUtils;
