import React from 'react';
import { Box, Typography } from '@mui/material';

import FroalaEditor from './Froala';
import { FroalaUploadRequest } from '@piccadilly-cloud/platform-services';
import { FieldError } from 'react-hook-form';

export interface WYSIWYGProps {
  dataKey: string;
  initialValue: string;
  onChange: (nextValue: string) => void;
  assetManagerImageOpts: () => FroalaUploadRequest;
  defaultText?: string;
  canvasEditor?: boolean;
  heightMax?: number;
  error?: FieldError;
  helperMessage?: string;
}

export default function WYSIWYG(props: WYSIWYGProps) {

  return (
    <>
      <Box>
        <FroalaEditor
          editorKey={props.dataKey}
          defaultHtmlValue={props.initialValue}
          onChange={props.onChange}
          assetManagerImageOpts={props.assetManagerImageOpts}
          defaultText={props.defaultText}
          canvasEditor={props.canvasEditor}
          heightMax={props.heightMax}
        />
      </Box>
      <Box sx={{ pl: 2, }}>
        <Typography variant='caption' color={props.error ? 'error' : 'unset'}>
          {props.helperMessage}
        </Typography>
      </Box>
    </>
  );
}